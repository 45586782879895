export const specs = {
    "openapi": "3.0.1",
    "info": {
        "title": "TCARE API Specification",
        "version": "2.0"
    },
    "servers": [
        {
            "url": "http://tcetest-api.tcare.ai/TCARE5/",
            "description": "Generated server url"
        }
    ],
    "tags": [
        {
            "name": "Email"
        },
        {
            "name": "ServiceUsage"
        },
        {
            "name": "Cases"
        },
        {
            "name": "Intake"
        },
        {
            "name": "Reports"
        },
        {
            "name": "Care Plans"
        },
        {
            "name": "TCare Connect"
        },
        {
            "name": "Misc"
        },
        {
            "name": "Users"
        },
        {
            "name": "referrer"
        },
        {
            "name": "User_Fields"
        },
        {
            "name": "Excel"
        },
        {
            "name": "Authentication"
        },
        {
            "name": "Notifications"
        },
        {
            "name": "Menus"
        },
        {
            "name": "Files"
        },
        {
            "name": "Organizations"
        },
        {
            "name": "Resources"
        },
        {
            "name": "Users",
            "description": "Interact with TCARE users"
        },
        {
            "name": "Organizations",
            "description": "Interact with TCARE organizations"
        },
        {
            "name": "DashBoard",
            "description": "Interact with TCARE organizations"
        },
        {
            "name": "Training"
        },
        {
            "name": "People"
        },
        {
            "name": "Menus",
            "description": "Retreives List of all Dropdown values and checkbox values"
        },
        {
            "name": "Case Reminder"
        },
        {
            "name": "Calendar"
        },
        {
            "name": "Version",
            "description": "Release and build information"
        },
        {
            "name": "Assessments"
        },
        {
            "name": "Notes"
        },
        {
            "name": "People",
            "description": "Endpoints interacting with Care Givers, Care Receivers, and Care Managers"
        },
        {
            "name": "SMS"
        }
    ],
    "paths": {
        "/organizations/{orgId}/users": {
            "get": {
                "tags": [
                    "Organizations",
                    "Users"
                ],
                "operationId": "listOrganizationUsers",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "searchRequest",
                        "in": "query",
                        "required": true
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/hal+json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            },
            "post": {
                "tags": [
                    "Organizations",
                    "Users"
                ],
                "operationId": "createOrganizationUser",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/UserCreateRequest"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/hal+json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/users": {
            "get": {
                "tags": [
                    "Users"
                ],
                "summary": "Retrive the Logged in User Details",
                "operationId": "getUserDetails",
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "404": {
                        "description": "User not found"
                    },
                    "200": {
                        "description": "User Details",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            },
            "post": {
                "tags": [
                    "Users"
                ],
                "summary": "Create New User in the System",
                "operationId": "createUser",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/UserCreateRequest"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "400": {
                        "description": "Invalid Request"
                    },
                    "200": {
                        "description": "User Created Successfully",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/users:reset-password": {
            "post": {
                "tags": [
                    "Email"
                ],
                "operationId": "resetPassword",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/ResetPassword"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "200": {
                        "description": "Email Send ",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/EmailModel"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "Email not found"
                    }
                }
            }
        },
        "/api/v2/users/{userName}:forgot-password": {
            "post": {
                "tags": [
                    "Email"
                ],
                "operationId": "forgotPassword",
                "parameters": [
                    {
                        "name": "userName",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "404": {
                        "description": "User not found"
                    },
                    "200": {
                        "description": "Email Send ",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/EmailModel"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/users/{email}:forgot-username": {
            "post": {
                "tags": [
                    "Email"
                ],
                "operationId": "forgotUsername",
                "parameters": [
                    {
                        "name": "email",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "404": {
                        "description": "User not found"
                    },
                    "200": {
                        "description": "Email Send ",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/EmailModel"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/users/-/email:check-availability": {
            "post": {
                "tags": [
                    "Users"
                ],
                "summary": "Check the availability of Email for new user",
                "operationId": "checkEmailAvailability",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/LmsValidationRequest"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "404": {
                        "description": "Caregiver not found"
                    },
                    "200": {
                        "description": "Email id available to use",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/resources": {
            "get": {
                "tags": [
                    "TCare Connect"
                ],
                "summary": "Adhoc Resource",
                "operationId": "getAdhoc",
                "responses": {
                    "200": {
                        "description": "Adhoc Resources",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/ResourceCustomAdd"
                                }
                            }
                        }
                    }
                }
            },
            "post": {
                "tags": [
                    "TCare Connect"
                ],
                "summary": "Get Resources",
                "operationId": "getResources",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/ResourceRequest"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "Resources",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/ResourceResponse"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/resources:add-custom": {
            "post": {
                "tags": [
                    "TCare Connect"
                ],
                "summary": "Add Resource",
                "operationId": "addCustomResource",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/ResourceCustomAdd"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "Resources",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/ResourceCustomAdd"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/referrer-cases:transfer": {
            "post": {
                "tags": [
                    "Cases"
                ],
                "operationId": "transferReferrerCase",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/TransferCase"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "404": {
                        "description": "Cases not found"
                    },
                    "200": {
                        "description": "Transfer the cases for one caremanager to another caremanager",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/CasePagedModel"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/referrals": {
            "post": {
                "tags": [
                    "referrer"
                ],
                "summary": "Creates a new case with Caregiver id and carereceiver id",
                "operationId": "createReferralCase",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/ReferrerCaseCreateRequest"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "200": {
                        "description": "Case Created",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "Caregiver not found"
                    }
                }
            }
        },
        "/api/v2/referralCase/upload": {
            "post": {
                "tags": [
                    "Excel"
                ],
                "operationId": "uploadFiles",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/ReferralDataLoad"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/organizations": {
            "get": {
                "tags": [
                    "Organizations"
                ],
                "summary": "Retrieve list of organization",
                "operationId": "listOrg",
                "responses": {
                    "400": {
                        "description": "Invalid "
                    },
                    "200": {
                        "description": "List of Organization",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/Organization"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "Organization not found"
                    }
                }
            },
            "post": {
                "tags": [
                    "Organizations"
                ],
                "operationId": "createOrganization",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/Organization"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "Organization Created",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/Organization"
                                }
                            }
                        }
                    },
                    "400": {
                        "description": "Invalid OrganizationId"
                    },
                    "404": {
                        "description": "Organization not found"
                    }
                }
            }
        },
        "/api/v2/organizations/{orgId}/files": {
            "get": {
                "tags": [
                    "Files"
                ],
                "operationId": "getAllFile",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid OrgId supplied"
                    },
                    "200": {
                        "description": "Reterive all file in Orgnization",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/NotesModel"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "Organization not found"
                    }
                }
            },
            "post": {
                "tags": [
                    "Files"
                ],
                "operationId": "uploadOrgFile",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/FileUpload"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "Organization file upload succesfully",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/NotesModel"
                                }
                            }
                        }
                    },
                    "400": {
                        "description": "Invalid OrgId supplied"
                    },
                    "404": {
                        "description": "Organization not found"
                    }
                }
            }
        },
        "/api/v2/organizations/{orgId}/cloneCareplan": {
            "post": {
                "tags": [
                    "Care Plans"
                ],
                "summary": "clone careplan",
                "operationId": "cloneCareplan",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/CreateAssessmentResponse"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "404": {
                        "description": "Person not found"
                    },
                    "200": {
                        "description": "User Updated",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/organizations/{orgId}/cases/{caseId}": {
            "get": {
                "tags": [
                    "Cases"
                ],
                "summary": "Retrieve Case summary with caseId",
                "operationId": "getCaseById",
                "parameters": [
                    {
                        "name": "caseId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    },
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "200": {
                        "description": "Case details",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/CaseSummaryModel"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "CaseId  not found"
                    }
                }
            },
            "post": {
                "tags": [
                    "Cases"
                ],
                "summary": "Create Associated cases with existing caregiver or existing careReceiver",
                "operationId": "associatedCase",
                "parameters": [
                    {
                        "name": "queue",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "caseId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/AssociatedCaseCreateRequest"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "Create Associated cases with existing caregiver or existing careReceiver",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/CasePagedModel"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/organizations/{orgId}/cases/{caseId}/assessments": {
            "post": {
                "tags": [
                    "Assessments"
                ],
                "summary": "Creating new Assessments",
                "operationId": "createAssessment",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "caseId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/AssessmentType"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "Assessment creation",
                        "content": {
                            "application/json": {}
                        }
                    }
                }
            }
        },
        "/api/v2/organizations/{orgId}/care-plans/{cpId}:finalize": {
            "post": {
                "tags": [
                    "Care Plans"
                ],
                "summary": "finalizeCarePlan",
                "operationId": "carePlanFinalize",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "cpId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/CarePlanFinalize"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "CarePlan finalized",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/CarePlan"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/organizations/{orgId}/care-plans/{cpId}/services": {
            "post": {
                "tags": [
                    "Care Plans"
                ],
                "summary": "SaveCarePlan",
                "operationId": "saveCarePlan",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "cpId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/CarePlan"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "CarePlan Details",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/CarePlan"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/organizations/{orgId}/care-plans/{cpId}/close": {
            "post": {
                "tags": [
                    "Care Plans"
                ],
                "summary": "Close Careplan",
                "operationId": "closeCareplan",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "cpId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Close Careplan",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/CarePlan"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/organizations/{orgId}/assessments/{assessId}/care-plans": {
            "post": {
                "tags": [
                    "Care Plans"
                ],
                "summary": "Create A New CarePlan ",
                "operationId": "createCarePlan",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "assessId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "CarePlan Created",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/organizations/cases:transfer": {
            "post": {
                "tags": [
                    "Cases"
                ],
                "operationId": "assignChildOrg",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/TransferCase"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "404": {
                        "description": "Cases not found"
                    },
                    "200": {
                        "description": "Transfer the cases for one caremanager to another caremanager",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/CasePagedModel"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/organizations/cases:assign": {
            "post": {
                "tags": [
                    "Cases"
                ],
                "operationId": "assignCases",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/TransferCase"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "404": {
                        "description": "Cases not found"
                    },
                    "200": {
                        "description": "Assign cases to caremanager to another caremanager",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/CasePagedModel"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/organizations/addCampaignId": {
            "post": {
                "tags": [
                    "Organizations"
                ],
                "operationId": "addCampaignId",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/OrgCampaign"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "400": {
                        "description": "Invalid Campaign Id"
                    },
                    "200": {
                        "description": "Campaign Id Created",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/OrgCampaign"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "Org Details not found"
                    }
                }
            }
        },
        "/api/v2/options/diseases": {
            "post": {
                "tags": [
                    "options-controller"
                ],
                "summary": "Api to get List of diseases",
                "operationId": "getDiseases",
                "parameters": [
                    {
                        "name": "lang",
                        "in": "query",
                        "required": false,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "diseases",
                        "content": {
                            "application/json": {}
                        }
                    }
                }
            }
        },
        "/api/v2/menus/close-case-reasons": {
            "post": {
                "tags": [
                    "Menus"
                ],
                "summary": "Retreives List of Closed Case Reasons",
                "operationId": "getReasons",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/ClosedReasonOrg"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "Closed Reasons",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/language/{language}": {
            "post": {
                "tags": [
                    "DashBoard"
                ],
                "summary": "Update People Language ",
                "operationId": "languagechanges",
                "parameters": [
                    {
                        "name": "language",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "200": {
                        "description": "Summary Details",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "AssessId  not found"
                    }
                }
            }
        },
        "/api/v2/intake": {
            "post": {
                "tags": [
                    "Intake"
                ],
                "summary": "Creates a new case with Caregiver id and carereceiver id",
                "operationId": "createCase",
                "parameters": [
                    {
                        "name": "lang",
                        "in": "query",
                        "required": false,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/ScreenerCreateRequest"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "200": {
                        "description": "Case Created",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "Caregiver not found"
                    }
                }
            }
        },
        "/api/v2/intake/referral": {
            "post": {
                "tags": [
                    "Intake"
                ],
                "summary": "Creates a new case with Caregiver id and carereceiver id",
                "operationId": "createReferralCase_1",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/ReferrerCaseCreateRequest"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "200": {
                        "description": "Case Created",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "Caregiver not found"
                    }
                }
            }
        },
        "/api/v2/intake/redirect": {
            "post": {
                "tags": [
                    "Intake"
                ],
                "summary": "Creates a new case with Caregiver id and carereceiver id",
                "operationId": "createResponse",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "type": "object"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "200": {
                        "description": "Case Created",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "Caregiver not found"
                    }
                }
            }
        },
        "/api/v2/intake/communicationValidation": {
            "post": {
                "tags": [
                    "Intake"
                ],
                "operationId": "get",
                "parameters": [
                    {
                        "name": "lang",
                        "in": "query",
                        "required": false,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/ScreenerCreateValidation"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "404": {
                        "description": "Cases not found"
                    },
                    "200": {
                        "description": "intake case create otp",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/PeopleModel"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/intake/care-giver/send-mail": {
            "post": {
                "tags": [
                    "Intake"
                ],
                "operationId": "getIntakeCaregiveMail",
                "parameters": [
                    {
                        "name": "lang",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/IntakeCaregiverMail"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "200": {
                        "description": "intake email Alert",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/PeopleModel"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "Cases not found"
                    }
                }
            }
        },
        "/api/v2/intake-cases": {
            "post": {
                "tags": [
                    "Cases"
                ],
                "summary": "Creates a new case through external screener",
                "operationId": "createIntakeCase",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/IntakeCaseCreateRequest"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "200": {
                        "description": "Case Created",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "Caregiver not found"
                    }
                }
            }
        },
        "/api/v2/helperbee": {
            "post": {
                "tags": [
                    "Cases"
                ],
                "summary": "Creates a new case with Caregiver id and carereceiver id",
                "operationId": "createReferral",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/ReferralCases"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "200": {
                        "description": "Case Created",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "Caregiver not found"
                    }
                }
            }
        },
        "/api/v2/fields": {
            "get": {
                "tags": [
                    "User_Fields"
                ],
                "operationId": "getFieldByScreen",
                "parameters": [
                    {
                        "name": "screenName",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "404": {
                        "description": "Cases not found"
                    },
                    "200": {
                        "description": "Assign the cases for the caremanagerlogged in  and create CareGiver",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/CasePagedModel"
                                }
                            }
                        }
                    }
                }
            },
            "post": {
                "tags": [
                    "User_Fields"
                ],
                "operationId": "saveFields",
                "parameters": [
                    {
                        "name": "screenName",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "type": "array",
                                "items": {
                                    "$ref": "#/components/schemas/UserField"
                                }
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "404": {
                        "description": "Cases not found"
                    },
                    "200": {
                        "description": "Assign the cases for the caremanagerlogged in  and create CareGiver",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/CasePagedModel"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/excel": {
            "post": {
                "tags": [
                    "Excel"
                ],
                "summary": "Api to create the users from Excel data",
                "operationId": "getQuestions",
                "parameters": [
                    {
                        "name": "fileName",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Data loaded",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/createCaseReminder": {
            "post": {
                "tags": [
                    "Case Reminder"
                ],
                "summary": "Creates a new case reminder ",
                "operationId": "createCaseReminder",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/CaseReminderCreateRequest"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "200": {
                        "description": "Case Reminder Created",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/CaseReminder"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "Case Id not found"
                    }
                }
            }
        },
        "/api/v2/cases": {
            "post": {
                "tags": [
                    "Cases"
                ],
                "summary": "Creates a new case with Caregiver id and carereceiver id",
                "operationId": "createCase_1",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/CaseCreateRequest"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "200": {
                        "description": "Case Created",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "Caregiver not found"
                    }
                }
            }
        },
        "/api/v2/cases:transfer": {
            "post": {
                "tags": [
                    "Cases"
                ],
                "operationId": "transferCase",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/TransferCase"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "404": {
                        "description": "Cases not found"
                    },
                    "200": {
                        "description": "Transfer the cases for one caremanager to another caremanager",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/CasePagedModel"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/cases:reopen": {
            "post": {
                "tags": [
                    "Cases"
                ],
                "operationId": "reopenCase",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "type": "array",
                                "items": {
                                    "type": "integer",
                                    "format": "int32"
                                }
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "404": {
                        "description": "Cases not found"
                    },
                    "200": {
                        "description": "reopen the closed cases in logged organization",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/CasePagedModel"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/cases:referrer": {
            "post": {
                "tags": [
                    "referrer"
                ],
                "operationId": "referralCaseAssign",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/ReferrerCaseCreateRequest"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "404": {
                        "description": "Cases not found"
                    },
                    "200": {
                        "description": "Assign the cases for the caremanagerlogged in  and create CareGiver",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/CasePagedModel"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/cases:close": {
            "post": {
                "tags": [
                    "Cases"
                ],
                "summary": "Closes the list of  ative cases with a Reason",
                "operationId": "closeCase",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/CaseCloseRequest"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "200": {
                        "description": "Case closed",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "CaseId not found"
                    }
                }
            }
        },
        "/api/v2/cases/{caseId}/resource-utilization": {
            "get": {
                "tags": [
                    "TCare Connect"
                ],
                "summary": "Resource Utilization",
                "operationId": "getResourceUtilization",
                "parameters": [
                    {
                        "name": "caseId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Adhoc Resources",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/ResourceCustomAdd"
                                }
                            }
                        }
                    }
                }
            },
            "post": {
                "tags": [
                    "TCare Connect"
                ],
                "summary": "Add Resource Utilization Feedback",
                "operationId": "updateResourceUtilization",
                "parameters": [
                    {
                        "name": "caseId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "type": "array",
                                "items": {
                                    "$ref": "#/components/schemas/ResourceUtilization"
                                }
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "Feedback submit for Resources",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/ResourceCustomAdd"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/cases/{caseId}/notes": {
            "get": {
                "tags": [
                    "Notes"
                ],
                "operationId": "getNotes",
                "parameters": [
                    {
                        "name": "caseId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "200": {
                        "description": "delete the casenotes for the case",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/NotesModel"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "Cases not found"
                    }
                }
            },
            "post": {
                "tags": [
                    "Notes"
                ],
                "operationId": "saveNotes",
                "parameters": [
                    {
                        "name": "caseId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "orgId",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/NotesCreateRequest"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "200": {
                        "description": "CaseNotes created ",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/NotesModel"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "Cases not found"
                    }
                }
            }
        },
        "/api/v2/cases/{caseId}/follow-ups": {
            "post": {
                "tags": [
                    "referrer"
                ],
                "summary": "Create a folloup entry for a referral case.",
                "operationId": "createReferralCaseFollowup",
                "parameters": [
                    {
                        "name": "caseId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    },
                    {
                        "name": "orgId",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/ReferrerCaseFollowupRequest"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "Create followup entry for the case",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/CasePagedModel"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/cases/{caseId}/files": {
            "get": {
                "tags": [
                    "Files"
                ],
                "operationId": "getAllCaseFile",
                "parameters": [
                    {
                        "name": "caseId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "responses": {
                    "404": {
                        "description": "Case not found"
                    },
                    "200": {
                        "description": "Reterive all Case file ",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/NotesModel"
                                }
                            }
                        }
                    },
                    "400": {
                        "description": "Invalid CaseId supplied"
                    }
                }
            },
            "post": {
                "tags": [
                    "Files"
                ],
                "operationId": "uploadOrgFile_1",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/CaseFileUpload"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "Case file upload succesfully",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/NotesModel"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "Case not found"
                    },
                    "400": {
                        "description": "Invalid CaseId supplied"
                    }
                }
            }
        },
        "/api/v2/caregivers/{pplId}/service-usage": {
            "get": {
                "tags": [
                    "ServiceUsage"
                ],
                "summary": "Retrive a persons service usage details",
                "operationId": "getServiceUsageById",
                "parameters": [
                    {
                        "name": "pplId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "404": {
                        "description": "Caregiver not found"
                    },
                    "200": {
                        "description": "service Usage Details Found",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/PplServiceUsage"
                                }
                            }
                        }
                    }
                }
            },
            "post": {
                "tags": [
                    "ServiceUsage"
                ],
                "summary": "create service usage entry for a  person for the chosen service",
                "operationId": "addServiceUsageByPpl",
                "parameters": [
                    {
                        "name": "pplId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    },
                    {
                        "name": "orgId",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/PplServiceUsage"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "200": {
                        "description": "service entry created",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/PplServiceUsage"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "Caregiver not found"
                    }
                }
            },
            "patch": {
                "tags": [
                    "ServiceUsage"
                ],
                "summary": "Updates service usage",
                "operationId": "updateServiceUsageById",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/PplServiceUsage"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "200": {
                        "description": "Service Usage Updated",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "Person not found"
                    }
                }
            }
        },
        "/api/v2/care-plans/{cpId}:share": {
            "post": {
                "tags": [
                    "Care Plans"
                ],
                "operationId": "shareCarePlanPdf",
                "parameters": [
                    {
                        "name": "cpId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    }
                ],
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/CarePlanPrintRequest"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/care-plans/{cpId}/adhoc": {
            "post": {
                "tags": [
                    "Care Plans"
                ],
                "summary": "saveAhoc",
                "operationId": "saveAdhoc",
                "parameters": [
                    {
                        "name": "cpId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/AdhocRequest"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "CarePlan Details",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/CarePlan"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/aunt_bertha_search": {
            "post": {
                "tags": [
                    "TCare Connect"
                ],
                "summary": "AuntBertha Search",
                "operationId": "auntBerthaSearch",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/ResourceRequest"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "AuntBertha Search",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/ResourceResponse"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/assessments/{assessId}/watchlist": {
            "post": {
                "tags": [
                    "Assessments"
                ],
                "summary": "Move To WatchList",
                "operationId": "moveToWatchList",
                "parameters": [
                    {
                        "name": "assessId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Move Successfully",
                        "content": {
                            "application/json": {}
                        }
                    }
                }
            }
        },
        "/api/v2/users/{pplId}:revive": {
            "patch": {
                "tags": [
                    "Users"
                ],
                "summary": "Revive the User(caremanager and Admin)",
                "operationId": "reviveUser",
                "parameters": [
                    {
                        "name": "pplId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "orgId",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "User Revived",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "User not found"
                    },
                    "400": {
                        "description": "Invalid Id"
                    }
                }
            }
        },
        "/api/v2/users/{pplId}:reactivate": {
            "patch": {
                "tags": [
                    "Users"
                ],
                "summary": "Reactivate the User(caremanager and Admin)",
                "operationId": "activateUser",
                "parameters": [
                    {
                        "name": "pplId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "orgId",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "User Activated",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "User not found"
                    },
                    "400": {
                        "description": "Invalid Id"
                    }
                }
            }
        },
        "/api/v2/users/{pplId}:deactivate": {
            "patch": {
                "tags": [
                    "Users"
                ],
                "summary": "Deactivate the User(caremanager and Admin)",
                "operationId": "deactivateUser",
                "parameters": [
                    {
                        "name": "pplId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "orgId",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "User Deactivated",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "User not found"
                    },
                    "400": {
                        "description": "Invalid Id"
                    }
                }
            }
        },
        "/api/v2/users/{pplId}/password": {
            "patch": {
                "tags": [
                    "Users"
                ],
                "summary": "Update User's Password",
                "operationId": "changePassword",
                "parameters": [
                    {
                        "name": "pplId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/PasswordChangeRequest"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "Password Updated",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    },
                    "400": {
                        "description": "Invalid password"
                    },
                    "404": {
                        "description": "User not found"
                    }
                }
            }
        },
        "/api/v2/users/{pplId}/notifications": {
            "get": {
                "tags": [
                    "Notifications"
                ],
                "summary": "Retrive List of Users Notification",
                "operationId": "getUserNotifications",
                "parameters": [
                    {
                        "name": "pplId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "User Notification List",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/NotificationResponse"
                                }
                            }
                        }
                    }
                }
            },
            "patch": {
                "tags": [
                    "Notifications"
                ],
                "summary": "Update the Users Notifications",
                "operationId": "updateNotification",
                "parameters": [
                    {
                        "name": "pplId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/NotifiationUpdateRequest"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "Notification Updated",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "boolean"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/updateCaseReminder": {
            "patch": {
                "tags": [
                    "Case Reminder"
                ],
                "operationId": "updateCaseReminder",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/CaseReminderUpdateRequest"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "200": {
                        "description": " CaseReminder successfully Edited",
                        "content": {
                            "application/json": {}
                        }
                    },
                    "404": {
                        "description": "CaseReminder not found"
                    }
                }
            }
        },
        "/api/v2/people/{pplId}": {
            "patch": {
                "tags": [
                    "People"
                ],
                "summary": "Updates Caregiver or Carereiver",
                "operationId": "updateUser",
                "parameters": [
                    {
                        "name": "pplId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "orgId",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/PersonUpdateRequest"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "404": {
                        "description": "Person not found"
                    },
                    "200": {
                        "description": "User Updated",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/organizations/{orgId}": {
            "get": {
                "tags": [
                    "Organizations"
                ],
                "summary": "reterive the organizaation by orgId",
                "operationId": "getOrganizationById",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid OrganizationId"
                    },
                    "404": {
                        "description": "Organization not found"
                    },
                    "200": {
                        "description": "Organization by OrgId",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/Organization"
                                }
                            }
                        }
                    }
                }
            },
            "patch": {
                "tags": [
                    "Organizations"
                ],
                "operationId": "updateOrganization",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    }
                ],
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/UpdateOrganizationRequest"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/organizations/{orgId}/users": {
            "get": {
                "tags": [
                    "Users"
                ],
                "summary": "Retrive List of all Active and Training Users or Terminated Users in given Organization by status",
                "operationId": "getUserListByStatus",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    },
                    {
                        "name": "status",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "User List",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    },
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "404": {
                        "description": "Org not found"
                    }
                }
            },
            "patch": {
                "tags": [
                    "Users"
                ],
                "summary": "Updates Users in given Organization",
                "operationId": "updateUser_1",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/UserUpdateRequest"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "404": {
                        "description": "Org not found"
                    },
                    "200": {
                        "description": "User Updated",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/organizations/{orgId}/saveSummary": {
            "patch": {
                "tags": [
                    "Assessments"
                ],
                "summary": "saving summary answers",
                "operationId": "saveSummary",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/SummarySubmitRequest"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "Assessment save",
                        "content": {
                            "application/json": {}
                        }
                    }
                }
            }
        },
        "/api/v2/organizations/{orgId}/notifications": {
            "get": {
                "tags": [
                    "Notifications"
                ],
                "summary": "Retrive List of Organization Notification",
                "operationId": "getOrgNotifications",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Org Notification List",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/NotificationResponse"
                                }
                            }
                        }
                    }
                }
            },
            "patch": {
                "tags": [
                    "Notifications"
                ],
                "summary": "Update the Organization Notifications",
                "operationId": "updateOrgNotification",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/NotifiationUpdateRequest"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "Notification Updated",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "boolean"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/organizations/{orgId}/cases/{caseId}/assessment": {
            "patch": {
                "tags": [
                    "Assessments"
                ],
                "summary": "saving assessment answers and calculating score at the end",
                "operationId": "saveAssessment",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "caseId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/AssessmentSubmitRequest"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "Assessment save",
                        "content": {
                            "application/json": {}
                        }
                    }
                }
            }
        },
        "/api/v2/organizations/activateCampaign": {
            "patch": {
                "tags": [
                    "Organizations"
                ],
                "summary": "Activate or Deactivate campaignID",
                "operationId": "activateCampaignId",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/OrgCampaign"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "400": {
                        "description": "Invalid "
                    },
                    "404": {
                        "description": "campaignID not found"
                    },
                    "200": {
                        "description": "Activate or Deactivate campaignID",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/OrgCampaign"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/notes/{noteId}": {
            "patch": {
                "tags": [
                    "Notes"
                ],
                "operationId": "updateNotes",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/NotesUpdateRequest"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "404": {
                        "description": "Cases not found"
                    },
                    "200": {
                        "description": " casenotes successfully Edited",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/NotesModel"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/intake/cases/{caseId}/assessment": {
            "patch": {
                "tags": [
                    "Intake"
                ],
                "summary": "saving assessment answers and calculating score at the end",
                "operationId": "saveAssessment_1",
                "parameters": [
                    {
                        "name": "caseId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "lang",
                        "in": "query",
                        "required": false,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/AssessmentSubmitRequest"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "Assessment save",
                        "content": {
                            "application/json": {}
                        }
                    }
                }
            }
        },
        "/api/v2/deleteCaseReminder": {
            "patch": {
                "tags": [
                    "Case Reminder"
                ],
                "operationId": "deleteCaseReminder",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/CaseReminderUpdateRequest"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "404": {
                        "description": "Cases not found"
                    },
                    "200": {
                        "description": "CaseReminder Deletion for the case",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/CaseReminderModel"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/cases/relationship": {
            "patch": {
                "tags": [
                    "Cases"
                ],
                "summary": "Update relationship betweem caregiver and carereceiver",
                "operationId": "updateRelationship",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/CaseRelationshipUpdate"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "200": {
                        "description": "relationship updated",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/CaseRelationshipUpdate"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "case not found"
                    }
                }
            }
        },
        "/api/v2/caseReminderActivate": {
            "patch": {
                "tags": [
                    "Case Reminder"
                ],
                "operationId": "caseReminderActivation",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/CaseReminderCreateRequest"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "404": {
                        "description": "Cases not found"
                    },
                    "200": {
                        "description": "CaseReminder Activation for the case",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/CaseReminderModel"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/caregivers/{pplId}": {
            "patch": {
                "tags": [
                    "Cases"
                ],
                "summary": "Updates Caregiver",
                "operationId": "updateUser_2",
                "parameters": [
                    {
                        "name": "pplId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "orgId",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/Caregiver"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "404": {
                        "description": "Person not found"
                    },
                    "200": {
                        "description": "User Updated",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/care-receivers/{pplId}": {
            "patch": {
                "tags": [
                    "Cases"
                ],
                "summary": "Updates Caregiver",
                "operationId": "updateUser_3",
                "parameters": [
                    {
                        "name": "pplId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "orgId",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/CareReceiver"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "404": {
                        "description": "Person not found"
                    },
                    "200": {
                        "description": "User Updated",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/assessments/{assessId}/reassessmentReason": {
            "patch": {
                "tags": [
                    "Assessments"
                ],
                "summary": "update reassessment reason",
                "operationId": "saveReassessmentReason",
                "parameters": [
                    {
                        "name": "assessId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/ReassessmentReason"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "update reassessment reason",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/whoami": {
            "get": {
                "tags": [
                    "Version"
                ],
                "operationId": "whoAmI",
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/whoami/{language}/{zone}": {
            "get": {
                "tags": [
                    "Version"
                ],
                "operationId": "whoAmI_1",
                "parameters": [
                    {
                        "name": "language",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "zone",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/version": {
            "get": {
                "tags": [
                    "Version"
                ],
                "operationId": "getVersion",
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object",
                                    "additionalProperties": {
                                        "type": "string"
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        "/version/advanced": {
            "get": {
                "tags": [
                    "Version"
                ],
                "operationId": "getAdvancedBuildInfo",
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/version/verbose": {
            "get": {
                "tags": [
                    "Version"
                ],
                "operationId": "getAdvancedBuildInfo_1",
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/{caseId}/caseReminders": {
            "get": {
                "tags": [
                    "Case Reminder"
                ],
                "summary": "Retrieve list of CaseReminder",
                "operationId": "listCaseReminder",
                "parameters": [
                    {
                        "name": "caseId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid "
                    },
                    "404": {
                        "description": "CaseReminder not found"
                    },
                    "200": {
                        "description": "List of CaseReminder",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/OrgCampaign"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/users/{pplId}": {
            "get": {
                "tags": [
                    "Users"
                ],
                "operationId": "getUser",
                "parameters": [
                    {
                        "name": "pplId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/hal+json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            },
            "delete": {
                "tags": [
                    "Users"
                ],
                "summary": "Remove/Terminated the User(caremanager and Admin)",
                "operationId": "removeUser",
                "parameters": [
                    {
                        "name": "pplId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "orgId",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "User Removed",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "User not found"
                    },
                    "400": {
                        "description": "Invalid Id"
                    }
                }
            }
        },
        "/api/v2/user": {
            "get": {
                "tags": [
                    "Users"
                ],
                "summary": "Retrive List of all Admin in given Organization",
                "operationId": "getAdminList",
                "parameters": [
                    {
                        "name": "roles",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "404": {
                        "description": "Org not found"
                    },
                    "200": {
                        "description": "Admin List",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/status": {
            "get": {
                "tags": [
                    "Cases"
                ],
                "operationId": "getStatusByScreen",
                "parameters": [
                    {
                        "name": "screenName",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "404": {
                        "description": "Cases not found"
                    },
                    "200": {
                        "description": "Assign the cases for the caremanagerlogged in  and create CareGiver",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/CasePagedModel"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/screen": {
            "get": {
                "tags": [
                    "User_Fields"
                ],
                "operationId": "getScreen",
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "404": {
                        "description": "Cases not found"
                    },
                    "200": {
                        "description": "Assign the cases for the caremanagerlogged in  and create CareGiver",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/CasePagedModel"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/referral/zone": {
            "get": {
                "tags": [
                    "referrer"
                ],
                "summary": "Creates a new case with Caregiver id and carereceiver id",
                "operationId": "getResponse",
                "parameters": [
                    {
                        "name": "zipCode",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "200": {
                        "description": "Case Created",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "Caregiver not found"
                    }
                }
            }
        },
        "/api/v2/orgnaizations/{orgId}/menus/assessment/{assessId}/reassessment-reason": {
            "get": {
                "tags": [
                    "Menus"
                ],
                "summary": "Retreive List of reassessment reason",
                "operationId": "getReassessmentReason",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "assessId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Education List",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/organizations/{orgId}/users/removed": {
            "get": {
                "tags": [
                    "Users"
                ],
                "summary": "Retrive List of Terminated Users in given Organization",
                "operationId": "getRemovedUserList",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "404": {
                        "description": "Org not found"
                    },
                    "200": {
                        "description": "Removed User List",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/organizations/{orgId}/users/active": {
            "get": {
                "tags": [
                    "Users"
                ],
                "summary": "Retrive List of all Active and Training Users in given Organization",
                "operationId": "getUserList",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "User List",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    },
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "404": {
                        "description": "Org not found"
                    }
                }
            }
        },
        "/api/v2/organizations/{orgId}/referrer": {
            "get": {
                "tags": [
                    "Users"
                ],
                "summary": "Retrive List of all Caremanager in given Organization",
                "operationId": "getReferrerList",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Caremanager List",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    },
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "404": {
                        "description": "Org not found"
                    }
                }
            }
        },
        "/api/v2/organizations/{orgId}/referrals": {
            "get": {
                "tags": [
                    "referrer"
                ],
                "summary": "Retrieve List of Pending Referral Cases in the Logged in Organization",
                "operationId": "listPendingReferralCases",
                "parameters": [
                    {
                        "name": "ascending",
                        "in": "query",
                        "required": false,
                        "schema": {
                            "type": "boolean"
                        }
                    },
                    {
                        "name": "page",
                        "in": "query",
                        "required": false,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "size",
                        "in": "query",
                        "required": false,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "sort",
                        "in": "query",
                        "required": false,
                        "schema": {
                            "type": "string",
                            "enum": [
                                "SORT_CASE_ID",
                                "SORT_SUBSCRIBER_ID",
                                "SORT_PROGRAM_NAME",
                                "SORT_CAREMANAGER_NAME",
                                "SORT_CAREGIVER_NAME",
                                "SORT_CARERECIPIENT_NAME",
                                "SORT_RELATIONSHIP",
                                "SORT_FOLLOW_UP_DATE",
                                "SORT_LAST_UPDATE",
                                "SORT_STATUS",
                                "SORT_DATA_CLOSED",
                                "SORT_SCORE",
                                "SORT_LOCATION",
                                "SORT_CLOSED_REASON",
                                "SORT_CARE_RECEIVER_COUNTY",
                                "SORT_REFERRED_BY",
                                "SORT_REMINDERS",
                                "SORT_REMINDER_DATE"
                            ]
                        }
                    },
                    {
                        "name": "caseIds",
                        "in": "query",
                        "required": false,
                        "schema": {
                            "type": "array",
                            "items": {
                                "type": "integer",
                                "format": "int64"
                            }
                        }
                    },
                    {
                        "name": "orgIds",
                        "in": "query",
                        "required": false,
                        "schema": {
                            "type": "array",
                            "items": {
                                "type": "integer",
                                "format": "int64"
                            }
                        }
                    },
                    {
                        "name": "cmIds",
                        "in": "query",
                        "required": false,
                        "schema": {
                            "type": "array",
                            "items": {
                                "type": "integer",
                                "format": "int64"
                            }
                        }
                    },
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "200": {
                        "description": "List of Cases",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/CasePagedModel"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "Cases not found"
                    }
                }
            }
        },
        "/api/v2/organizations/{orgId}/organizations": {
            "get": {
                "tags": [
                    "Organizations"
                ],
                "summary": "Retrieve list of organization",
                "operationId": "listChildOrg",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid "
                    },
                    "200": {
                        "description": "List of Organization",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/ChildOrganization"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "Organization not found"
                    }
                }
            }
        },
        "/api/v2/organizations/{orgId}/organizations/child:list": {
            "get": {
                "tags": [
                    "Organizations"
                ],
                "summary": "Retrieve list of organization",
                "operationId": "listChildOrgList",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid "
                    },
                    "200": {
                        "description": "List of Organization",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/ChildOrganization"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "Organization not found"
                    }
                }
            }
        },
        "/api/v2/organizations/{orgId}/cases": {
            "get": {
                "tags": [
                    "Cases"
                ],
                "summary": "Retrieves List of Closed Cases in the Logged in Organization",
                "operationId": "closedCases",
                "parameters": [
                    {
                        "name": "pageable",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "$ref": "#/components/schemas/Pageable"
                        }
                    },
                    {
                        "name": "queue",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "status",
                        "in": "query",
                        "required": false,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "search",
                        "in": "query",
                        "required": false,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "caseIds",
                        "in": "query",
                        "required": false,
                        "schema": {
                            "type": "array",
                            "items": {
                                "type": "integer",
                                "format": "int64"
                            }
                        }
                    },
                    {
                        "name": "orgIds",
                        "in": "query",
                        "required": false,
                        "schema": {
                            "type": "array",
                            "items": {
                                "type": "integer",
                                "format": "int64"
                            }
                        }
                    },
                    {
                        "name": "cmIds",
                        "in": "query",
                        "required": false,
                        "schema": {
                            "type": "array",
                            "items": {
                                "type": "integer",
                                "format": "int64"
                            }
                        }
                    },
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "404": {
                        "description": "Cases not found"
                    },
                    "200": {
                        "description": "List of Cases",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/CasePagedModel"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/organizations/{orgId}/cases:search": {
            "get": {
                "tags": [
                    "Cases"
                ],
                "summary": "Retrieves List of Closed Cases in the Logged in Organization",
                "operationId": "getCasesBySubId",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    },
                    {
                        "name": "subId",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "404": {
                        "description": "Cases not found"
                    },
                    "200": {
                        "description": "List of Cases",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/CasePagedModel"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/organizations/{orgId}/cases/{caseId}/relationship": {
            "get": {
                "tags": [
                    "Cases"
                ],
                "summary": "Retrieve Case summary with caseId",
                "operationId": "getCaseRelationshipId",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    },
                    {
                        "name": "caseId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "200": {
                        "description": "Case details",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/CaseSummaryModel"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "CaseId  not found"
                    }
                }
            }
        },
        "/api/v2/organizations/{orgId}/cases/{caseId}/follow-ups": {
            "get": {
                "tags": [
                    "referrer"
                ],
                "summary": "Retrieve List of followup entries for a case ",
                "operationId": "listPendingReferralFollowups",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "caseId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "200": {
                        "description": "List of Followups",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/ReferrerCaseFollowup"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "Cases not found"
                    }
                }
            }
        },
        "/api/v2/organizations/{orgId}/cases-pagination": {
            "get": {
                "tags": [
                    "Cases"
                ],
                "summary": "Retrieves List of Closed Cases in the Logged in Organization",
                "operationId": "listCases",
                "parameters": [
                    {
                        "name": "pageable",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "$ref": "#/components/schemas/Pageable"
                        }
                    },
                    {
                        "name": "ascending",
                        "in": "query",
                        "required": false,
                        "schema": {
                            "type": "boolean"
                        }
                    },
                    {
                        "name": "page",
                        "in": "query",
                        "required": false,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "size",
                        "in": "query",
                        "required": false,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "sort",
                        "in": "query",
                        "required": false,
                        "schema": {
                            "type": "string",
                            "enum": [
                                "SORT_CASE_ID",
                                "SORT_SUBSCRIBER_ID",
                                "SORT_PROGRAM_NAME",
                                "SORT_CAREMANAGER_NAME",
                                "SORT_CAREGIVER_NAME",
                                "SORT_CARERECIPIENT_NAME",
                                "SORT_RELATIONSHIP",
                                "SORT_FOLLOW_UP_DATE",
                                "SORT_LAST_UPDATE",
                                "SORT_STATUS",
                                "SORT_DATA_CLOSED",
                                "SORT_SCORE",
                                "SORT_LOCATION",
                                "SORT_CLOSED_REASON",
                                "SORT_CARE_RECEIVER_COUNTY",
                                "SORT_REFERRED_BY",
                                "SORT_REMINDERS",
                                "SORT_REMINDER_DATE"
                            ]
                        }
                    },
                    {
                        "name": "queue",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "sortList",
                        "in": "query",
                        "required": false,
                        "schema": {
                            "type": "array",
                            "items": {
                                "type": "string"
                            }
                        }
                    },
                    {
                        "name": "sortOrder",
                        "in": "query",
                        "required": false,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "caseIds",
                        "in": "query",
                        "required": false,
                        "schema": {
                            "type": "array",
                            "items": {
                                "type": "integer",
                                "format": "int64"
                            }
                        }
                    },
                    {
                        "name": "orgIds",
                        "in": "query",
                        "required": false,
                        "schema": {
                            "type": "array",
                            "items": {
                                "type": "integer",
                                "format": "int64"
                            }
                        }
                    },
                    {
                        "name": "cmIds",
                        "in": "query",
                        "required": false,
                        "schema": {
                            "type": "array",
                            "items": {
                                "type": "integer",
                                "format": "int64"
                            }
                        }
                    },
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "404": {
                        "description": "Cases not found"
                    },
                    "200": {
                        "description": "List of Cases",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/CasePagedModel"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/organizations/{orgId}/care-plans/{cpId}/queue/{queue}": {
            "get": {
                "tags": [
                    "Care Plans"
                ],
                "summary": "CarePlan Details",
                "operationId": "getCarePlan",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "cpId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "queue",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "Timezone",
                        "in": "query",
                        "required": false,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "lang",
                        "in": "query",
                        "required": false,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "CarePlan Details",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/CarePlan"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/organizations/{orgId}/care-managers": {
            "get": {
                "tags": [
                    "Users"
                ],
                "summary": "Retrive List of all Caremanager in given Organization",
                "operationId": "getCaremanagerList",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Caremanager List",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    },
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "404": {
                        "description": "Org not found"
                    }
                }
            }
        },
        "/api/v2/organizations/{orgId}/care-managers/{pplId}/dashboard": {
            "get": {
                "tags": [
                    "DashBoard"
                ],
                "summary": "Retreive dashboard details with pplId ",
                "operationId": "getDashBoard",
                "parameters": [
                    {
                        "name": "pplId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "200": {
                        "description": "Summary Details",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "AssessId  not found"
                    }
                }
            }
        },
        "/api/v2/organizations/{orgId}/care-managers/{pplId}/clients": {
            "get": {
                "tags": [
                    "People"
                ],
                "summary": "Retrive caregiver's details with pplId",
                "operationId": "getClientList",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "pplId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "200": {
                        "description": "caregiver Details Found",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/PeopleModel"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "Caregiver not found"
                    }
                }
            }
        },
        "/api/v2/organizations/{orgId}/campaignID": {
            "get": {
                "tags": [
                    "Organizations"
                ],
                "summary": "Retrieve list of campaignID",
                "operationId": "listCampaignId",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid "
                    },
                    "200": {
                        "description": "List of campaignID",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/OrgCampaign"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "campaignID not found"
                    }
                }
            }
        },
        "/api/v2/organizations/{orgId}/associated": {
            "get": {
                "tags": [
                    "Organizations"
                ],
                "summary": "Retrieve list of organization",
                "operationId": "assocOrg",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid "
                    },
                    "200": {
                        "description": "List of Organization",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/ChildOrganization"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "Organization not found"
                    }
                }
            }
        },
        "/api/v2/organizations/{orgId}/assessments/{assessId}:print": {
            "get": {
                "tags": [
                    "Assessments"
                ],
                "operationId": "sendAssessPrint",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "assessId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    },
                    {
                        "name": "timezone",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/organizations/{orgId}/assessments/{assessId}/summary": {
            "get": {
                "tags": [
                    "Assessments"
                ],
                "summary": "Retreive summary detiails by assessId",
                "operationId": "getSummary",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "assessId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "200": {
                        "description": "Summary Details",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "AssessId  not found"
                    }
                }
            }
        },
        "/api/v2/organizations/{orgId}/assessments/{assessId}/summaryQuestion": {
            "get": {
                "tags": [
                    "Assessments"
                ],
                "summary": "Retreive List of Questions",
                "operationId": "getSummaryQuestions",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "assessId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Education List",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/organizations/{orgId}/assessments/{assessId}/score": {
            "get": {
                "tags": [
                    "Assessments"
                ],
                "summary": "Retreive List of Questions",
                "operationId": "getScore",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "assessId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Education List",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/organizations/{orgId}/assessments/{assessId}/pages/{page}/waquestions": {
            "get": {
                "tags": [
                    "Assessments"
                ],
                "summary": "Retreive List of Questions",
                "operationId": "getWshQuestions",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "assessId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    },
                    {
                        "name": "page",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Education List",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/organizations/{orgId}/assessments/{assessId}/pages/{page}/questions": {
            "get": {
                "tags": [
                    "Assessments"
                ],
                "summary": "Retreive List of Questions",
                "operationId": "getQuestions_1",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "assessId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    },
                    {
                        "name": "page",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "screener",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "currentPage",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Education List",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/organizations/-/files/{fileId}": {
            "get": {
                "tags": [
                    "Files"
                ],
                "operationId": "readOrgFile",
                "parameters": [
                    {
                        "name": "fileId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Orgnization file by fileId",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/NotesModel"
                                }
                            }
                        }
                    },
                    "400": {
                        "description": "Invalid OrgId supplied"
                    },
                    "404": {
                        "description": "Organization not found"
                    }
                }
            },
            "delete": {
                "tags": [
                    "Files"
                ],
                "operationId": "deleteFile",
                "parameters": [
                    {
                        "name": "fileId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "File deleted Successfully",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/NotesModel"
                                }
                            }
                        }
                    },
                    "400": {
                        "description": "Invalid OrgId supplied"
                    },
                    "404": {
                        "description": "Organization not found"
                    }
                }
            }
        },
        "/api/v2/organizaitons/{orgId}/cases/{caseId}/demographic": {
            "get": {
                "tags": [
                    "Cases"
                ],
                "summary": "Retreive the demographic user",
                "operationId": "getDemographic",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    },
                    {
                        "name": "caseId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "404": {
                        "description": "CaseId  not found"
                    },
                    "200": {
                        "description": "User Details",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/options/vendors": {
            "get": {
                "tags": [
                    "options-controller"
                ],
                "summary": "Retreive List of Relationships",
                "operationId": "getVendors",
                "responses": {
                    "200": {
                        "description": "Relationship List",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/options/service-units": {
            "get": {
                "tags": [
                    "options-controller"
                ],
                "summary": "Retreive List of Relationships",
                "operationId": "getUnit",
                "responses": {
                    "200": {
                        "description": "Relationship List",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/options/follow-up-reasons": {
            "get": {
                "tags": [
                    "options-controller"
                ],
                "summary": "Retrieve List of followup reasons ",
                "operationId": "listFollowupReasons",
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "200": {
                        "description": "List of Followups",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/ReferrerCaseFollowup"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "Cases not found"
                    }
                }
            }
        },
        "/api/v2/menus/states": {
            "get": {
                "tags": [
                    "Menus"
                ],
                "summary": "Retreives List of States and its Abbreviations",
                "operationId": "getStates",
                "parameters": [
                    {
                        "name": "lang",
                        "in": "query",
                        "required": false,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "States List",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/menus/service-usage": {
            "get": {
                "tags": [
                    "Menus"
                ],
                "summary": "Retreive List of Relationships",
                "operationId": "getServiceUsage",
                "responses": {
                    "200": {
                        "description": "Relationship List",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/menus/roles/org/{orgId}": {
            "get": {
                "tags": [
                    "Menus"
                ],
                "summary": "Retreives List of Roles in the system",
                "operationId": "getRoles",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Roles List",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/menus/relationships": {
            "get": {
                "tags": [
                    "Menus"
                ],
                "summary": "Retreive List of Relationships",
                "operationId": "getRelationships",
                "parameters": [
                    {
                        "name": "lang",
                        "in": "query",
                        "required": false,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Relationship List",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/menus/races": {
            "get": {
                "tags": [
                    "Menus"
                ],
                "summary": "Retreives List of Races",
                "operationId": "getRace",
                "parameters": [
                    {
                        "name": "lang",
                        "in": "query",
                        "required": false,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Race and Ethinicity",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/menus/pronouns": {
            "get": {
                "tags": [
                    "Menus"
                ],
                "summary": "Retreive List of Pronouns",
                "operationId": "getPronouns",
                "responses": {
                    "200": {
                        "description": "Pronouns List",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/menus/marital-status": {
            "get": {
                "tags": [
                    "Menus"
                ],
                "summary": "Retreives List of Marital Status",
                "operationId": "getMaritalStatus",
                "responses": {
                    "200": {
                        "description": "MaritalStatus List",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/menus/languages": {
            "get": {
                "tags": [
                    "Menus"
                ],
                "summary": "Retreives List of Language Preferences",
                "operationId": "getLanguages",
                "parameters": [
                    {
                        "name": "lang",
                        "in": "query",
                        "required": false,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Languages List",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/menus/insurance-options": {
            "get": {
                "tags": [
                    "Menus"
                ],
                "summary": "Retreives the Insurance Options",
                "operationId": "getInsurance",
                "responses": {
                    "200": {
                        "description": "Insurance List",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/menus/incomes": {
            "get": {
                "tags": [
                    "Menus"
                ],
                "summary": "Retreive List of Incomes",
                "operationId": "getImcome",
                "responses": {
                    "200": {
                        "description": "Income List",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/menus/genders": {
            "get": {
                "tags": [
                    "Menus"
                ],
                "summary": "Retreive List of Genders",
                "operationId": "getGender",
                "parameters": [
                    {
                        "name": "lang",
                        "in": "query",
                        "required": false,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Genders List",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/menus/funding": {
            "get": {
                "tags": [
                    "Menus"
                ],
                "summary": "Retreive List of Relationships",
                "operationId": "getFunding",
                "responses": {
                    "200": {
                        "description": "Relationship List",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/menus/employments": {
            "get": {
                "tags": [
                    "Menus"
                ],
                "summary": "Retreive List of Employments",
                "operationId": "getEmployment",
                "responses": {
                    "200": {
                        "description": "Employments List",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/menus/educations": {
            "get": {
                "tags": [
                    "Menus"
                ],
                "summary": "Retreive List of Educations",
                "operationId": "getEducation",
                "responses": {
                    "200": {
                        "description": "Education List",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/menus/disability": {
            "get": {
                "tags": [
                    "Menus"
                ],
                "summary": "Retreive List of Disability",
                "operationId": "getDisability",
                "responses": {
                    "200": {
                        "description": "Disability List",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/menus/case-programName": {
            "get": {
                "tags": [
                    "Menus"
                ],
                "summary": "Retreive List of case program name",
                "operationId": "getReassessmentReason_1",
                "responses": {
                    "200": {
                        "description": "Education List",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/menus/case-assessmentType": {
            "get": {
                "tags": [
                    "Menus"
                ],
                "summary": "Retreive List of case assessment type",
                "operationId": "getAssessmentType",
                "responses": {
                    "200": {
                        "description": "Education List",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/intake/zones": {
            "get": {
                "tags": [
                    "Intake"
                ],
                "summary": "Creates a new case with Caregiver id and carereceiver id",
                "operationId": "getResponse_1",
                "parameters": [
                    {
                        "name": "zipCode",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "200": {
                        "description": "Case Created",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "Caregiver not found"
                    }
                }
            }
        },
        "/api/v2/intake/request": {
            "get": {
                "tags": [
                    "Intake"
                ],
                "summary": "Creates a new case with Caregiver id and carereceiver id",
                "operationId": "getResponse_2",
                "parameters": [
                    {
                        "name": "id",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "200": {
                        "description": "Case Created",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "Caregiver not found"
                    }
                }
            }
        },
        "/api/v2/intake/ppl/{pplId}/otp/{otp}/validation": {
            "get": {
                "tags": [
                    "Intake"
                ],
                "operationId": "getIntakeSmsOtp",
                "parameters": [
                    {
                        "name": "pplId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    },
                    {
                        "name": "otp",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "404": {
                        "description": "Cases not found"
                    },
                    "200": {
                        "description": "intake case create otp",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/PeopleModel"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/intake/organizations/{orgId}/clients/{pplId}/emailValidate": {
            "get": {
                "tags": [
                    "People"
                ],
                "summary": "To validate the subscriber id  ",
                "operationId": "getEmailValidation",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    },
                    {
                        "name": "pplId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    },
                    {
                        "name": "email",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Intake Org Details",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/intake/organizations/{orgId}/clients/{pplId}/SubIdValidate": {
            "get": {
                "tags": [
                    "People"
                ],
                "summary": "To Validate the Email Address ",
                "operationId": "getSubValidation",
                "parameters": [
                    {
                        "name": "orgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    },
                    {
                        "name": "pplId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    },
                    {
                        "name": "subId",
                        "in": "query",
                        "required": false,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Intake Org Details",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/intake/orgDetails": {
            "get": {
                "tags": [
                    "Menus"
                ],
                "summary": "To retreive Org Details ",
                "operationId": "getIntakeOrgDetails",
                "parameters": [
                    {
                        "name": "orgCode",
                        "in": "query",
                        "required": false,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Intake Org Details",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/intake/cases/{caseId}/demographic": {
            "get": {
                "tags": [
                    "Intake"
                ],
                "summary": "Retreive the demographic user",
                "operationId": "getDemographic_1",
                "parameters": [
                    {
                        "name": "caseId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    },
                    {
                        "name": "lang",
                        "in": "query",
                        "required": false,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "404": {
                        "description": "CaseId  not found"
                    },
                    "200": {
                        "description": "User Details",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/intake/assessments/{assessId}/pages/{page}/questions/{orgCode}": {
            "get": {
                "tags": [
                    "Intake"
                ],
                "summary": "Retreive List of Questions",
                "operationId": "getQuestions_2",
                "parameters": [
                    {
                        "name": "assessId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    },
                    {
                        "name": "page",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "orgCode",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "screener",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "lang",
                        "in": "query",
                        "required": false,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Education List",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/clients/{pplId}": {
            "get": {
                "tags": [
                    "People"
                ],
                "summary": "Retrive caregiver's details with pplId",
                "operationId": "getClinetById",
                "parameters": [
                    {
                        "name": "pplId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "200": {
                        "description": "caregiver Details Found",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/PeopleModel"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "Caregiver not found"
                    }
                }
            }
        },
        "/api/v2/cases/{caseId}/files/{fileId}": {
            "get": {
                "tags": [
                    "Files"
                ],
                "operationId": "readCaseFile",
                "parameters": [
                    {
                        "name": "caseId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "fileId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "responses": {
                    "404": {
                        "description": "Case not found"
                    },
                    "400": {
                        "description": "Invalid CaseId supplied"
                    },
                    "200": {
                        "description": "Case file view succcessfully",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/NotesModel"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/cases/{caseId}/emailHistory": {
            "get": {
                "tags": [
                    "Email"
                ],
                "operationId": "getEmailCommunication",
                "parameters": [
                    {
                        "name": "caseId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    }
                ],
                "responses": {
                    "404": {
                        "description": "Case not found"
                    },
                    "200": {
                        "description": "Reterive all Case sms ",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/NotesModel"
                                }
                            }
                        }
                    },
                    "400": {
                        "description": "Invalid CaseId supplied"
                    }
                }
            }
        },
        "/api/v2/cases/{caseId}/communication": {
            "get": {
                "tags": [
                    "Cases"
                ],
                "summary": "Retrieve the Caregiver's message and call summary from twillio with caseId",
                "operationId": "getCommunications",
                "parameters": [
                    {
                        "name": "caseId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "404": {
                        "description": "CaseId  not found"
                    },
                    "200": {
                        "description": "Message and call logs",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/CommunicationSummaryModel"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/cases/{caseId}/associatedCase": {
            "get": {
                "tags": [
                    "Cases"
                ],
                "summary": "Retrieve the List of Cases Associated to the Current Case's caregiver and carereciever",
                "operationId": "getAssociatedCase",
                "parameters": [
                    {
                        "name": "caseId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "200": {
                        "description": "List of Associated Cases",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/CommunicationSummaryModel"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "CaseId  not found"
                    }
                }
            }
        },
        "/api/v2/caregivers/{pplId}/services": {
            "get": {
                "tags": [
                    "ServiceUsage"
                ],
                "summary": "Obtain Service list configured in options",
                "operationId": "getServiceOptions",
                "parameters": [
                    {
                        "name": "pplId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "200": {
                        "description": "Service list found",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/PplServiceUsage"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "Caregiver not found"
                    }
                }
            }
        },
        "/api/v2/caregivers/{cgId}": {
            "get": {
                "tags": [
                    "People"
                ],
                "summary": "Retrive caregiver's details with pplId",
                "operationId": "getCareGiverById",
                "parameters": [
                    {
                        "name": "cgId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "200": {
                        "description": "caregiver Details Found",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/PeopleModel"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "Caregiver not found"
                    }
                }
            }
        },
        "/api/v2/care-receivers/{crId}": {
            "get": {
                "tags": [
                    "People"
                ],
                "summary": "Retrive carereceiver's details with pplId",
                "operationId": "getCareRecieverById",
                "parameters": [
                    {
                        "name": "crId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "200": {
                        "description": "carereceivers Details Found",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/PeopleModel"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "Caregiver not found"
                    }
                }
            }
        },
        "/api/v2/care-plans/{cpId}/services/{serviceId}/keywords": {
            "get": {
                "tags": [
                    "Care Plans"
                ],
                "summary": "Get Careplan Keywords",
                "operationId": "getKeywords",
                "parameters": [
                    {
                        "name": "cpId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "serviceId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "keywords",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/CarePlan"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/assessments/{assessId}:printForm": {
            "get": {
                "tags": [
                    "Assessments"
                ],
                "operationId": "sendAssessPrinFormt",
                "parameters": [
                    {
                        "name": "assessId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/assessments/{assessId}/summary:print": {
            "get": {
                "tags": [
                    "Assessments"
                ],
                "operationId": "sendSummaryPrint",
                "parameters": [
                    {
                        "name": "assessId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/api/v2/cases/{caseId}/notes/{notesId}": {
            "delete": {
                "tags": [
                    "Notes"
                ],
                "operationId": "deleteNotes",
                "parameters": [
                    {
                        "name": "caseId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "notesId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "orgId",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "200": {
                        "description": "delete the casenotes for the case",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/NotesModel"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "Cases not found"
                    }
                }
            }
        },
        "/api/v2/cases/-/files/{fileId}": {
            "delete": {
                "tags": [
                    "Files"
                ],
                "operationId": "deleteCaseFile",
                "parameters": [
                    {
                        "name": "fileId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "responses": {
                    "404": {
                        "description": "Case not found"
                    },
                    "200": {
                        "description": "File deleted Successfully",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/NotesModel"
                                }
                            }
                        }
                    },
                    "400": {
                        "description": "Invalid CaseId supplied"
                    }
                }
            }
        },
        "/api/v2/caregivers/{pplId}/service-usage/{pplServiceUsageId}": {
            "delete": {
                "tags": [
                    "ServiceUsage"
                ],
                "summary": "delete the service usage",
                "operationId": "deleteServiceUsage",
                "parameters": [
                    {
                        "name": "pplId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "pplServiceUsageId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    {
                        "name": "orgId",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                ],
                "responses": {
                    "400": {
                        "description": "Invalid id supplied"
                    },
                    "200": {
                        "description": "Service list found",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/PplServiceUsage"
                                }
                            }
                        }
                    },
                    "404": {
                        "description": "Caregiver not found"
                    }
                }
            }
        }
    },
    "components": {
        "schemas": {
            "StreetAddress": {
                "type": "object",
                "properties": {
                    "state": {
                        "type": "string"
                    },
                    "country": {
                        "type": "string"
                    },
                    "county": {
                        "type": "string"
                    },
                    "addressLine1": {
                        "type": "string"
                    },
                    "addressLine2": {
                        "type": "string"
                    },
                    "city": {
                        "type": "string"
                    },
                    "postalCode": {
                        "type": "string"
                    },
                    "addressLine3": {
                        "type": "string"
                    }
                }
            },
            "UserCreateRequest": {
                "type": "object",
                "properties": {
                    "address": {
                        "$ref": "#/components/schemas/StreetAddress"
                    },
                    "year": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "month": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "day": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "asian": {
                        "type": "boolean"
                    },
                    "black": {
                        "type": "boolean"
                    },
                    "educationId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "employmentId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "homePhone": {
                        "type": "string"
                    },
                    "incomeId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "middleEastern": {
                        "type": "boolean"
                    },
                    "middleName": {
                        "type": "string"
                    },
                    "prefferedName": {
                        "type": "string"
                    },
                    "gender": {
                        "type": "string"
                    },
                    "education": {
                        "type": "string"
                    },
                    "employment": {
                        "type": "string"
                    },
                    "maritalStatus": {
                        "type": "string"
                    },
                    "roles": {
                        "type": "array",
                        "items": {
                            "type": "string"
                        }
                    },
                    "income": {
                        "type": "string"
                    },
                    "organizationId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "email": {
                        "type": "string"
                    },
                    "rolesId": {
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    "refused": {
                        "type": "boolean"
                    },
                    "firstName": {
                        "type": "string"
                    },
                    "lastName": {
                        "type": "string"
                    },
                    "phone": {
                        "type": "string"
                    },
                    "consentToContact": {
                        "type": "boolean"
                    },
                    "hispanicOrLatino": {
                        "type": "boolean"
                    },
                    "nativeHawaiian": {
                        "type": "boolean"
                    },
                    "userName": {
                        "type": "string"
                    },
                    "notification": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/UserNotifications"
                        }
                    },
                    "americanIndian": {
                        "type": "boolean"
                    },
                    "password": {
                        "type": "string"
                    },
                    "ssn": {
                        "type": "string"
                    },
                    "white": {
                        "type": "boolean"
                    },
                    "status": {
                        "type": "string",
                        "enum": [
                            "UNKNOWN",
                            "ACTIVE",
                            "DEACTIVATED",
                            "TERMINATED",
                            "PENDING_CERTIFICATION"
                        ]
                    }
                }
            },
            "UserNotifications": {
                "type": "object",
                "properties": {
                    "pplColumnName": {
                        "type": "string"
                    },
                    "emailColumnName": {
                        "type": "string"
                    },
                    "notificationId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "notificationName": {
                        "type": "string"
                    },
                    "notificationEnabled": {
                        "type": "boolean"
                    }
                }
            },
            "ResetPassword": {
                "type": "object",
                "properties": {
                    "pass": {
                        "type": "string"
                    },
                    "userName": {
                        "type": "string"
                    },
                    "token": {
                        "type": "string"
                    }
                }
            },
            "EmailModel": {
                "type": "object",
                "properties": {
                    "content": {
                        "type": "string"
                    },
                    "fileName": {
                        "type": "string"
                    },
                    "userMail": {
                        "type": "string"
                    },
                    "attachment": {
                        "type": "string"
                    },
                    "subject": {
                        "type": "string"
                    },
                    "links": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/Link"
                        }
                    }
                }
            },
            "Link": {
                "type": "object",
                "properties": {
                    "rel": {
                        "type": "string"
                    },
                    "href": {
                        "type": "string"
                    },
                    "hreflang": {
                        "type": "string"
                    },
                    "media": {
                        "type": "string"
                    },
                    "title": {
                        "type": "string"
                    },
                    "type": {
                        "type": "string"
                    },
                    "deprecation": {
                        "type": "string"
                    },
                    "profile": {
                        "type": "string"
                    },
                    "name": {
                        "type": "string"
                    }
                }
            },
            "LmsValidationRequest": {
                "type": "object",
                "properties": {
                    "email": {
                        "type": "string"
                    },
                    "personId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "organizationName": {
                        "type": "string"
                    },
                    "userName": {
                        "type": "string"
                    }
                }
            },
            "ResourceRequest": {
                "type": "object",
                "properties": {
                    "orgId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "apiKey": {
                        "type": "string"
                    },
                    "zipcode": {
                        "type": "string"
                    },
                    "search": {
                        "type": "string"
                    },
                    "distance": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "username": {
                        "type": "string"
                    },
                    "password": {
                        "type": "string"
                    },
                    "keyword": {
                        "type": "string"
                    },
                    "env": {
                        "type": "string"
                    }
                }
            },
            "Resource": {
                "type": "object",
                "properties": {
                    "state": {
                        "type": "string"
                    },
                    "address": {
                        "type": "string"
                    },
                    "source": {
                        "type": "string"
                    },
                    "cost": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "lgbt": {
                        "type": "boolean"
                    },
                    "detail": {
                        "type": "string"
                    },
                    "virtual": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "findHelpId": {
                        "type": "string"
                    },
                    "addressId": {
                        "type": "string"
                    },
                    "county": {
                        "type": "string"
                    },
                    "eligibility": {
                        "type": "string"
                    },
                    "programNames": {
                        "type": "string"
                    },
                    "operationHours": {
                        "type": "array",
                        "items": {
                            "type": "string"
                        }
                    },
                    "zipCode": {
                        "type": "string"
                    },
                    "webLink": {
                        "type": "string"
                    },
                    "city": {
                        "type": "string"
                    },
                    "languages": {
                        "type": "string"
                    },
                    "email": {
                        "type": "string"
                    },
                    "rdbId": {
                        "type": "string"
                    },
                    "rating": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "phone": {
                        "type": "string"
                    },
                    "longitude": {
                        "type": "string"
                    },
                    "latitude": {
                        "type": "string"
                    },
                    "distance": {
                        "type": "string"
                    },
                    "serviceName": {
                        "type": "string"
                    },
                    "tags": {
                        "type": "string"
                    },
                    "keyword": {
                        "type": "string"
                    }
                }
            },
            "ResourceResponse": {
                "type": "object",
                "properties": {
                    "localResource": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/Resource"
                        }
                    },
                    "remoteResource": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/Resource"
                        }
                    },
                    "longitude": {
                        "type": "string"
                    },
                    "latitude": {
                        "type": "string"
                    }
                }
            },
            "ResourceCustomAdd": {
                "type": "object",
                "properties": {
                    "state": {
                        "type": "string"
                    },
                    "address": {
                        "type": "string"
                    },
                    "source": {
                        "type": "string"
                    },
                    "orgId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "county": {
                        "type": "string"
                    },
                    "programNames": {
                        "type": "string"
                    },
                    "officeName": {
                        "type": "string"
                    },
                    "operationHours": {
                        "type": "string"
                    },
                    "zipCode": {
                        "type": "string"
                    },
                    "nearbyZip": {
                        "type": "string"
                    },
                    "webLink": {
                        "type": "string"
                    },
                    "facebookLink": {
                        "type": "string"
                    },
                    "twitterLink": {
                        "type": "string"
                    },
                    "city": {
                        "type": "string"
                    },
                    "email": {
                        "type": "string"
                    },
                    "rdbId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "notes": {
                        "type": "string"
                    },
                    "phone": {
                        "type": "string"
                    },
                    "serviceName": {
                        "type": "string"
                    },
                    "keyword": {
                        "type": "string"
                    }
                }
            },
            "TransferCase": {
                "type": "object",
                "properties": {
                    "orgId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "caseId": {
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    "childOrgId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "newCareManagerId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "referrerId": {
                        "type": "integer",
                        "format": "int32"
                    }
                }
            },
            "CaseModel": {
                "type": "object",
                "properties": {
                    "location": {
                        "type": "string"
                    },
                    "measureHistory": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/MeasureHistory"
                        }
                    },
                    "referredByName": {
                        "type": "string"
                    },
                    "latestAssessId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "latestCpId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "caseCreated": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "caseLastUpdated": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "dateClosed": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "cgPrefLang": {
                        "type": "string"
                    },
                    "closedReason": {
                        "type": "string"
                    },
                    "subscriberId": {
                        "type": "string"
                    },
                    "caseId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "followupDate": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "county": {
                        "type": "string"
                    },
                    "programName": {
                        "type": "string"
                    },
                    "caregiverName": {
                        "type": "string"
                    },
                    "careReceiverName": {
                        "type": "string"
                    },
                    "rescreenActivated": {
                        "type": "boolean"
                    },
                    "caseStatus": {
                        "type": "string"
                    },
                    "caremanagerName": {
                        "type": "string"
                    },
                    "assessType": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "careManagerId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "organizationId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "reminderNotes": {
                        "type": "string"
                    },
                    "caseReminderDate": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "vipStatus": {
                        "type": "boolean"
                    },
                    "statusId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "messageType": {
                        "type": "string"
                    },
                    "caregiverId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "careReceiverId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "relationship": {
                        "type": "string"
                    },
                    "referredBy": {
                        "type": "integer",
                        "format": "int64"
                    },
                    "notesLastModified": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "reason": {
                        "type": "boolean"
                    },
                    "links": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/Link"
                        }
                    }
                }
            },
            "CasePagedModel": {
                "type": "object",
                "properties": {
                    "links": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/Link"
                        }
                    },
                    "content": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/CaseModel"
                        }
                    },
                    "page": {
                        "$ref": "#/components/schemas/PageMetadata"
                    }
                }
            },
            "MeasureHistory": {
                "type": "object",
                "properties": {
                    "screener": {
                        "type": "boolean"
                    },
                    "itpNowId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "iscore": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "itpNow": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "relationshipHml": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "objectiveScore": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "objectiveHml": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "stressScore": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "stressHml": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "upliftScore": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "upliftHml": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "depressionScore": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "depressionHml": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "iddScore": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "iddHml": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "pbScore": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "ascore": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "assessId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "relationshipScore": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "assessmentTransLabel": {
                        "type": "string"
                    }
                }
            },
            "PageMetadata": {
                "type": "object",
                "properties": {
                    "size": {
                        "type": "integer",
                        "format": "int64"
                    },
                    "totalElements": {
                        "type": "integer",
                        "format": "int64"
                    },
                    "totalPages": {
                        "type": "integer",
                        "format": "int64"
                    },
                    "number": {
                        "type": "integer",
                        "format": "int64"
                    }
                }
            },
            "CareReceiverCreateRequest": {
                "type": "object",
                "properties": {
                    "address": {
                        "$ref": "#/components/schemas/StreetAddress"
                    },
                    "language": {
                        "type": "string"
                    },
                    "phoneAuthorized": {
                        "type": "boolean"
                    },
                    "subscriberId": {
                        "type": "string"
                    },
                    "uid": {
                        "type": "string"
                    },
                    "asian": {
                        "type": "boolean"
                    },
                    "black": {
                        "type": "boolean"
                    },
                    "educationId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "employmentId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "hispanic": {
                        "type": "boolean"
                    },
                    "homePhone": {
                        "type": "string"
                    },
                    "incomeId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "middleEastern": {
                        "type": "boolean"
                    },
                    "middleName": {
                        "type": "string"
                    },
                    "nativeAmerican": {
                        "type": "boolean"
                    },
                    "pacificIslander": {
                        "type": "boolean"
                    },
                    "claimDate": {
                        "type": "string"
                    },
                    "coordinatorName": {
                        "type": "string"
                    },
                    "regionalManager": {
                        "type": "string"
                    },
                    "caseManager": {
                        "type": "string"
                    },
                    "createdBy": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "maritalStat": {
                        "type": "string"
                    },
                    "assessType": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "gender": {
                        "type": "string"
                    },
                    "pronouns": {
                        "type": "string"
                    },
                    "disability": {
                        "type": "string"
                    },
                    "personId": {
                        "type": "integer",
                        "format": "int64"
                    },
                    "dobDay": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "dobMonth": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "dobYear": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "otherRelationship": {
                        "type": "string"
                    },
                    "relationshipId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "phoneType": {
                        "type": "string"
                    },
                    "firstName": {
                        "type": "string"
                    },
                    "lastName": {
                        "type": "string"
                    },
                    "emailAddress": {
                        "type": "string"
                    },
                    "referrerId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "areaCode": {
                        "type": "string"
                    },
                    "phone": {
                        "type": "string"
                    },
                    "uninsured": {
                        "type": "boolean"
                    },
                    "preferredName": {
                        "type": "string"
                    },
                    "otherRace": {
                        "type": "boolean"
                    },
                    "secondaryPhone": {
                        "type": "string"
                    },
                    "ssn": {
                        "type": "string"
                    },
                    "white": {
                        "type": "boolean"
                    },
                    "concentToContat": {
                        "type": "boolean"
                    },
                    "samsId": {
                        "type": "string"
                    },
                    "multiRacial": {
                        "type": "boolean"
                    },
                    "secondaryPhoneType": {
                        "type": "string"
                    }
                }
            },
            "CaregiverCreateRequest": {
                "type": "object",
                "properties": {
                    "address": {
                        "$ref": "#/components/schemas/StreetAddress"
                    },
                    "language": {
                        "type": "string"
                    },
                    "phoneAuthorized": {
                        "type": "boolean"
                    },
                    "subscriberId": {
                        "type": "string"
                    },
                    "uid": {
                        "type": "string"
                    },
                    "asian": {
                        "type": "boolean"
                    },
                    "black": {
                        "type": "boolean"
                    },
                    "educationId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "employmentId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "hispanic": {
                        "type": "boolean"
                    },
                    "homePhone": {
                        "type": "string"
                    },
                    "incomeId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "middleEastern": {
                        "type": "boolean"
                    },
                    "middleName": {
                        "type": "string"
                    },
                    "nativeAmerican": {
                        "type": "boolean"
                    },
                    "pacificIslander": {
                        "type": "boolean"
                    },
                    "pgName": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "programName": {
                        "type": "string"
                    },
                    "createdBy": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "maritalStat": {
                        "type": "string"
                    },
                    "gender": {
                        "type": "string"
                    },
                    "pronouns": {
                        "type": "string"
                    },
                    "disability": {
                        "type": "string"
                    },
                    "personId": {
                        "type": "integer",
                        "format": "int64"
                    },
                    "dobDay": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "dobMonth": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "dobYear": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "otherRelationship": {
                        "type": "string"
                    },
                    "relationshipId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "phoneType": {
                        "type": "string"
                    },
                    "firstName": {
                        "type": "string"
                    },
                    "lastName": {
                        "type": "string"
                    },
                    "emailAddress": {
                        "type": "string"
                    },
                    "areaCode": {
                        "type": "string"
                    },
                    "phone": {
                        "type": "string"
                    },
                    "uninsured": {
                        "type": "boolean"
                    },
                    "preferredName": {
                        "type": "string"
                    },
                    "otherRace": {
                        "type": "boolean"
                    },
                    "secondaryPhone": {
                        "type": "string"
                    },
                    "ssn": {
                        "type": "string"
                    },
                    "white": {
                        "type": "boolean"
                    },
                    "concentToContat": {
                        "type": "boolean"
                    },
                    "samsId": {
                        "type": "string"
                    },
                    "multiRacial": {
                        "type": "boolean"
                    }
                }
            },
            "ReferrerCaseCreateRequest": {
                "type": "object",
                "properties": {
                    "referrerCaseFollowup": {
                        "$ref": "#/components/schemas/ReferrerCaseFollowupRequest"
                    },
                    "orgCode": {
                        "type": "string"
                    },
                    "caregiver": {
                        "$ref": "#/components/schemas/CaregiverCreateRequest"
                    },
                    "campaignId": {
                        "type": "string"
                    },
                    "careReceiver": {
                        "$ref": "#/components/schemas/CareReceiverCreateRequest"
                    },
                    "caseId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "noCaregiver": {
                        "type": "boolean"
                    },
                    "organizationId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "pcspDate": {
                        "type": "string",
                        "format": "date-time"
                    }
                }
            },
            "ReferrerCaseFollowupRequest": {
                "type": "object",
                "properties": {
                    "referredByName": {
                        "type": "string"
                    },
                    "caseId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "followupDate": {
                        "type": "string"
                    },
                    "reasonName": {
                        "type": "string"
                    },
                    "notes": {
                        "type": "string"
                    },
                    "rcfId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "reason": {
                        "type": "integer",
                        "format": "int32"
                    }
                }
            },
            "ReferralDataLoad": {
                "type": "object",
                "properties": {
                    "file": {
                        "type": "string"
                    },
                    "orgId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "campaignId": {
                        "type": "string"
                    },
                    "referredBy": {
                        "type": "integer",
                        "format": "int32"
                    }
                }
            },
            "Organization": {
                "required": [
                    "organizationName",
                    "phone"
                ],
                "type": "object",
                "properties": {
                    "active": {
                        "type": "boolean"
                    },
                    "createdAt": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "updatedAt": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "emailNotifi1": {
                        "type": "boolean"
                    },
                    "emailNotifi2": {
                        "type": "boolean"
                    },
                    "emailNotifi3": {
                        "type": "boolean"
                    },
                    "emailNotifi4": {
                        "type": "boolean"
                    },
                    "emailNotifi5": {
                        "type": "boolean"
                    },
                    "emailNotifi6": {
                        "type": "boolean"
                    },
                    "emailNotifi7": {
                        "type": "boolean"
                    },
                    "emailNotifi8": {
                        "type": "boolean"
                    },
                    "emailNotifi9": {
                        "type": "boolean"
                    },
                    "emailNotif10": {
                        "type": "boolean"
                    },
                    "emailNotifi11": {
                        "type": "boolean"
                    },
                    "emailNotifi12": {
                        "type": "boolean"
                    },
                    "emailNotifi13": {
                        "type": "boolean"
                    },
                    "emailNotifi14": {
                        "type": "boolean"
                    },
                    "emailNotifi15": {
                        "type": "boolean"
                    },
                    "orgCodeDisable": {
                        "type": "boolean"
                    },
                    "appVersion": {
                        "type": "string"
                    },
                    "orgCode": {
                        "type": "string"
                    },
                    "reminder1": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "reminder2": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "reminder3": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "automaticReminder": {
                        "type": "boolean"
                    },
                    "userCreateMailAlert": {
                        "type": "boolean"
                    },
                    "organizationAddress": {
                        "$ref": "#/components/schemas/StreetAddress"
                    },
                    "activeTimeoutInMs": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "apiServiceActivated": {
                        "type": "boolean"
                    },
                    "iddServiceActivated": {
                        "type": "boolean"
                    },
                    "inactiveTimeoutInMs": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "militaryServiceActivated": {
                        "type": "boolean"
                    },
                    "resourceDatabaseApi": {
                        "type": "boolean"
                    },
                    "schedulingActivated": {
                        "type": "boolean"
                    },
                    "telephonyActivated": {
                        "type": "boolean"
                    },
                    "calendarId": {
                        "type": "string"
                    },
                    "chatbotActivated": {
                        "type": "boolean"
                    },
                    "dynamicScreener": {
                        "type": "boolean"
                    },
                    "lockoutTimeInMs": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "loginAttempts": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "primaryContact": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "reportActivated": {
                        "type": "boolean"
                    },
                    "sicCd": {
                        "type": "string"
                    },
                    "followupDays": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "filterRdb": {
                        "type": "boolean"
                    },
                    "caremanagerMail": {
                        "type": "boolean"
                    },
                    "passwordExpirationAge": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "organizationUpload": {
                        "type": "boolean"
                    },
                    "licenseExpirationDate": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "specializedQuestions": {
                        "type": "boolean"
                    },
                    "rescreenActivated": {
                        "type": "boolean"
                    },
                    "referralDyadRequired": {
                        "type": "boolean"
                    },
                    "subscriberIdRequired": {
                        "type": "boolean"
                    },
                    "caregiverMail": {
                        "type": "boolean"
                    },
                    "cgIntake": {
                        "type": "boolean"
                    },
                    "dobFullRequired": {
                        "type": "boolean"
                    },
                    "contactCG": {
                        "type": "boolean"
                    },
                    "showSSN": {
                        "type": "boolean"
                    },
                    "showCampaign": {
                        "type": "boolean"
                    },
                    "zipCodeEnableCR": {
                        "type": "boolean"
                    },
                    "vipEnabled": {
                        "type": "boolean"
                    },
                    "organizationId": {
                        "type": "integer",
                        "format": "int64"
                    },
                    "statId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "areaCode": {
                        "type": "string"
                    },
                    "licenses": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "phone": {
                        "type": "string"
                    },
                    "organizationName": {
                        "type": "string"
                    }
                }
            },
            "FileUpload": {
                "type": "object",
                "properties": {
                    "file": {
                        "type": "string"
                    },
                    "fileName": {
                        "type": "string"
                    },
                    "fileId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "orgId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "details": {
                        "type": "string"
                    },
                    "uploadDate": {
                        "type": "string",
                        "format": "date-time"
                    }
                }
            },
            "NotesCreateRequest": {
                "type": "object",
                "properties": {
                    "notes": {
                        "type": "string"
                    }
                }
            },
            "NotesModel": {
                "type": "object",
                "properties": {
                    "notes": {
                        "type": "string"
                    },
                    "links": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/Link"
                        }
                    }
                }
            },
            "ApiError": {
                "type": "object",
                "properties": {
                    "cause": {
                        "type": "object",
                        "properties": {
                            "stackTrace": {
                                "type": "array",
                                "items": {
                                    "type": "object",
                                    "properties": {
                                        "classLoaderName": {
                                            "type": "string"
                                        },
                                        "moduleName": {
                                            "type": "string"
                                        },
                                        "moduleVersion": {
                                            "type": "string"
                                        },
                                        "methodName": {
                                            "type": "string"
                                        },
                                        "fileName": {
                                            "type": "string"
                                        },
                                        "lineNumber": {
                                            "type": "integer",
                                            "format": "int32"
                                        },
                                        "className": {
                                            "type": "string"
                                        },
                                        "nativeMethod": {
                                            "type": "boolean"
                                        }
                                    }
                                }
                            },
                            "message": {
                                "type": "string"
                            },
                            "suppressed": {
                                "type": "array",
                                "items": {
                                    "type": "object",
                                    "properties": {
                                        "stackTrace": {
                                            "type": "array",
                                            "items": {
                                                "type": "object",
                                                "properties": {
                                                    "classLoaderName": {
                                                        "type": "string"
                                                    },
                                                    "moduleName": {
                                                        "type": "string"
                                                    },
                                                    "moduleVersion": {
                                                        "type": "string"
                                                    },
                                                    "methodName": {
                                                        "type": "string"
                                                    },
                                                    "fileName": {
                                                        "type": "string"
                                                    },
                                                    "lineNumber": {
                                                        "type": "integer",
                                                        "format": "int32"
                                                    },
                                                    "className": {
                                                        "type": "string"
                                                    },
                                                    "nativeMethod": {
                                                        "type": "boolean"
                                                    }
                                                }
                                            }
                                        },
                                        "message": {
                                            "type": "string"
                                        },
                                        "localizedMessage": {
                                            "type": "string"
                                        }
                                    }
                                }
                            },
                            "localizedMessage": {
                                "type": "string"
                            }
                        }
                    },
                    "stackTrace": {
                        "type": "array",
                        "items": {
                            "type": "object",
                            "properties": {
                                "classLoaderName": {
                                    "type": "string"
                                },
                                "moduleName": {
                                    "type": "string"
                                },
                                "moduleVersion": {
                                    "type": "string"
                                },
                                "methodName": {
                                    "type": "string"
                                },
                                "fileName": {
                                    "type": "string"
                                },
                                "lineNumber": {
                                    "type": "integer",
                                    "format": "int32"
                                },
                                "className": {
                                    "type": "string"
                                },
                                "nativeMethod": {
                                    "type": "boolean"
                                }
                            }
                        }
                    },
                    "errorCode": {
                        "type": "string"
                    },
                    "errorMessage": {
                        "type": "string"
                    },
                    "txnStatus": {
                        "type": "boolean"
                    },
                    "message": {
                        "type": "string"
                    },
                    "suppressed": {
                        "type": "array",
                        "items": {
                            "type": "object",
                            "properties": {
                                "stackTrace": {
                                    "type": "array",
                                    "items": {
                                        "type": "object",
                                        "properties": {
                                            "classLoaderName": {
                                                "type": "string"
                                            },
                                            "moduleName": {
                                                "type": "string"
                                            },
                                            "moduleVersion": {
                                                "type": "string"
                                            },
                                            "methodName": {
                                                "type": "string"
                                            },
                                            "fileName": {
                                                "type": "string"
                                            },
                                            "lineNumber": {
                                                "type": "integer",
                                                "format": "int32"
                                            },
                                            "className": {
                                                "type": "string"
                                            },
                                            "nativeMethod": {
                                                "type": "boolean"
                                            }
                                        }
                                    }
                                },
                                "message": {
                                    "type": "string"
                                },
                                "localizedMessage": {
                                    "type": "string"
                                }
                            }
                        }
                    },
                    "localizedMessage": {
                        "type": "string"
                    }
                }
            },
            "CreateAssessmentResponse": {
                "type": "object",
                "properties": {
                    "caseId": {
                        "type": "integer",
                        "format": "int64"
                    },
                    "assessmentId": {
                        "type": "integer",
                        "format": "int64"
                    },
                    "screenerAssess": {
                        "type": "boolean"
                    },
                    "carePlanId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "error": {
                        "$ref": "#/components/schemas/ApiError"
                    }
                }
            },
            "AssociatedCaseCreateRequest": {
                "type": "object",
                "properties": {
                    "caregiver": {
                        "$ref": "#/components/schemas/CaregiverCreateRequest"
                    },
                    "careReceiver": {
                        "$ref": "#/components/schemas/CareReceiverCreateRequest"
                    },
                    "personId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "pcspDate": {
                        "type": "string",
                        "format": "date-time"
                    }
                }
            },
            "AssessmentType": {
                "type": "object",
                "properties": {
                    "screener": {
                        "type": "integer",
                        "format": "int32"
                    }
                }
            },
            "CarePlanFinalize": {
                "type": "object",
                "properties": {
                    "caseId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "followupDate": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "reminder1": {
                        "type": "string"
                    },
                    "reminder2": {
                        "type": "string"
                    },
                    "reminder3": {
                        "type": "string"
                    },
                    "edited": {
                        "type": "boolean"
                    },
                    "automaticReminder": {
                        "type": "boolean"
                    },
                    "cmResponsibility": {
                        "type": "string"
                    },
                    "cgResponsibility": {
                        "type": "string"
                    },
                    "caseGoals": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/CaseGoal"
                        }
                    },
                    "sigDate": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "reminderDate": {
                        "$ref": "#/components/schemas/ReminderDates"
                    },
                    "existing": {
                        "type": "boolean"
                    }
                }
            },
            "CaseGoal": {
                "type": "object",
                "properties": {
                    "priority": {
                        "type": "string"
                    },
                    "goalName": {
                        "type": "string"
                    },
                    "goalId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "goalDetails": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/GoalDetail"
                        }
                    }
                }
            },
            "GoalDetail": {
                "type": "object",
                "properties": {
                    "desiredOutcome": {
                        "type": "string"
                    },
                    "strategyId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "strategyName": {
                        "type": "string"
                    },
                    "strategyDetails": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/StrategyDetail"
                        }
                    }
                }
            },
            "GoalService": {
                "type": "object",
                "properties": {
                    "servieType": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "goalType": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "goalTypeName": {
                        "type": "string"
                    },
                    "caseId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "serviceTypeName": {
                        "type": "string"
                    },
                    "categoryName": {
                        "type": "string"
                    },
                    "goalId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "carePlanId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "serviceId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "categoryId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "special": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "services": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/Services"
                        }
                    }
                }
            },
            "ReminderDates": {
                "type": "object",
                "properties": {
                    "reminder1": {
                        "type": "string"
                    },
                    "reminder2": {
                        "type": "string"
                    },
                    "reminder3": {
                        "type": "string"
                    }
                }
            },
            "Services": {
                "type": "object",
                "properties": {
                    "comment": {
                        "type": "string"
                    },
                    "webLink": {
                        "type": "string"
                    },
                    "selectedServiceId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "adhocContent": {
                        "type": "string"
                    },
                    "deleted": {
                        "type": "boolean"
                    },
                    "serviceId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "rdbId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "adhocTemplate": {
                        "type": "string"
                    },
                    "serviceName": {
                        "type": "string"
                    },
                    "info": {
                        "type": "string"
                    },
                    "keyword": {
                        "type": "string"
                    }
                }
            },
            "StrategyDetail": {
                "type": "object",
                "properties": {
                    "caregoryId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "highlight": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "categoryName": {
                        "type": "string"
                    },
                    "goalTypeDetails": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/GoalService"
                        }
                    }
                }
            },
            "CarePlan": {
                "type": "object",
                "properties": {
                    "followupDate": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "timeZoneOffset": {
                        "type": "string"
                    },
                    "reminder1": {
                        "type": "string"
                    },
                    "reminder2": {
                        "type": "string"
                    },
                    "reminder3": {
                        "type": "string"
                    },
                    "edited": {
                        "type": "boolean"
                    },
                    "automaticReminder": {
                        "type": "boolean"
                    },
                    "cmResponsibility": {
                        "type": "string"
                    },
                    "cgResponsibility": {
                        "type": "string"
                    },
                    "caseGoals": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/CaseGoal"
                        }
                    },
                    "reminderDate": {
                        "$ref": "#/components/schemas/ReminderDates"
                    },
                    "existing": {
                        "type": "boolean"
                    },
                    "prefLanguage": {
                        "type": "string"
                    }
                }
            },
            "OrgCampaign": {
                "type": "object",
                "properties": {
                    "active": {
                        "type": "boolean"
                    },
                    "createdAt": {
                        "type": "string"
                    },
                    "orgCampaignId": {
                        "type": "integer",
                        "format": "int64"
                    },
                    "caseType": {
                        "type": "string"
                    },
                    "campaignUrl": {
                        "type": "string"
                    },
                    "modifiedBy": {
                        "type": "string"
                    },
                    "modifiedAt": {
                        "type": "string"
                    },
                    "orgId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "campaignId": {
                        "type": "string"
                    },
                    "createdBy": {
                        "type": "string"
                    }
                }
            },
            "ClosedReasonOrg": {
                "type": "object",
                "properties": {
                    "caseId": {
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        }
                    }
                }
            },
            "IntakeCareReceiverRequest": {
                "type": "object",
                "properties": {
                    "address": {
                        "$ref": "#/components/schemas/StreetAddress"
                    },
                    "subscriberId": {
                        "type": "string"
                    },
                    "createdBy": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "dobDay": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "dobMonth": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "dobYear": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "otherRelationship": {
                        "type": "string"
                    },
                    "relationshipId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "firstName": {
                        "type": "string"
                    },
                    "lastName": {
                        "type": "string"
                    },
                    "otherRelationShip": {
                        "type": "string"
                    }
                }
            },
            "IntakeCaregiverRequest": {
                "type": "object",
                "properties": {
                    "address": {
                        "$ref": "#/components/schemas/StreetAddress"
                    },
                    "subscriberId": {
                        "type": "string"
                    },
                    "uid": {
                        "type": "string"
                    },
                    "asian": {
                        "type": "boolean"
                    },
                    "black": {
                        "type": "boolean"
                    },
                    "hispanic": {
                        "type": "boolean"
                    },
                    "middleEastern": {
                        "type": "boolean"
                    },
                    "nativeAmerican": {
                        "type": "boolean"
                    },
                    "pacificIslander": {
                        "type": "boolean"
                    },
                    "phoneNumber": {
                        "type": "string"
                    },
                    "programName": {
                        "type": "string"
                    },
                    "createdBy": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "gender": {
                        "type": "string"
                    },
                    "race": {
                        "type": "array",
                        "items": {
                            "type": "string"
                        }
                    },
                    "email": {
                        "type": "string"
                    },
                    "dobDay": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "dobMonth": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "dobYear": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "firstName": {
                        "type": "string"
                    },
                    "lastName": {
                        "type": "string"
                    },
                    "otherRace": {
                        "type": "boolean"
                    },
                    "white": {
                        "type": "boolean"
                    },
                    "multiRacial": {
                        "type": "boolean"
                    }
                }
            },
            "ScreenerCreateRequest": {
                "type": "object",
                "properties": {
                    "orgCode": {
                        "type": "string"
                    },
                    "caregiver": {
                        "$ref": "#/components/schemas/IntakeCaregiverRequest"
                    },
                    "campaignId": {
                        "type": "string"
                    },
                    "careReceiver": {
                        "$ref": "#/components/schemas/IntakeCareReceiverRequest"
                    },
                    "caseId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "assessmentId": {
                        "type": "integer",
                        "format": "int64"
                    }
                }
            },
            "ScreenerCreateValidation": {
                "type": "object",
                "properties": {
                    "orgCode": {
                        "type": "string"
                    },
                    "campaignId": {
                        "type": "string"
                    },
                    "phoneNumber": {
                        "type": "string"
                    },
                    "email": {
                        "type": "string"
                    }
                }
            },
            "PeopleModel": {
                "type": "object",
                "properties": {
                    "address": {
                        "$ref": "#/components/schemas/StreetAddress"
                    },
                    "language": {
                        "type": "string"
                    },
                    "phoneAuthorized": {
                        "type": "boolean"
                    },
                    "subscriberId": {
                        "type": "string"
                    },
                    "asian": {
                        "type": "boolean"
                    },
                    "black": {
                        "type": "boolean"
                    },
                    "educationId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "employmentId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "hispanic": {
                        "type": "boolean"
                    },
                    "homePhone": {
                        "type": "string"
                    },
                    "incomeId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "middleEastern": {
                        "type": "boolean"
                    },
                    "middleName": {
                        "type": "string"
                    },
                    "nativeAmerican": {
                        "type": "boolean"
                    },
                    "pacificIslander": {
                        "type": "boolean"
                    },
                    "pgName": {
                        "type": "string"
                    },
                    "maritalStat": {
                        "type": "string"
                    },
                    "assessType": {
                        "type": "string"
                    },
                    "gender": {
                        "type": "string"
                    },
                    "education": {
                        "type": "string"
                    },
                    "employment": {
                        "type": "string"
                    },
                    "pronouns": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "disability": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "income": {
                        "type": "string"
                    },
                    "personId": {
                        "type": "integer",
                        "format": "int64"
                    },
                    "dobDay": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "dobMonth": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "dobYear": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "pcspDate": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "otherRelationship": {
                        "type": "string"
                    },
                    "relationshipId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "fromDate": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "toDate": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "vipStatus": {
                        "type": "boolean"
                    },
                    "phoneType": {
                        "type": "string"
                    },
                    "firstName": {
                        "type": "string"
                    },
                    "lastName": {
                        "type": "string"
                    },
                    "emailAddress": {
                        "type": "string"
                    },
                    "areaCode": {
                        "type": "string"
                    },
                    "phone": {
                        "type": "string"
                    },
                    "uninsured": {
                        "type": "boolean"
                    },
                    "numPeopleInHome": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "preferredName": {
                        "type": "string"
                    },
                    "crDetails": {
                        "type": "boolean"
                    },
                    "emailValid": {
                        "type": "boolean"
                    },
                    "otherRace": {
                        "type": "boolean"
                    },
                    "ssn": {
                        "type": "string"
                    },
                    "white": {
                        "type": "boolean"
                    },
                    "cgDetails": {
                        "type": "boolean"
                    },
                    "assessTypeId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "concentToContat": {
                        "type": "boolean"
                    },
                    "multiRacial": {
                        "type": "boolean"
                    },
                    "pgNameId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "status": {
                        "type": "string",
                        "enum": [
                            "UNKNOWN",
                            "ACTIVE",
                            "DEACTIVATED",
                            "TERMINATED",
                            "PENDING_CERTIFICATION"
                        ]
                    },
                    "links": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/Link"
                        }
                    }
                }
            },
            "IntakeCaregiverMail": {
                "type": "object",
                "properties": {
                    "orgId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "assessId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "email": {
                        "type": "string"
                    }
                }
            },
            "Answers": {
                "type": "object",
                "properties": {
                    "answerLabel": {
                        "type": "string"
                    },
                    "selected": {
                        "type": "boolean"
                    },
                    "textBoxEnaled": {
                        "type": "boolean"
                    },
                    "answerValue": {
                        "type": "string"
                    },
                    "answerId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "otherText": {
                        "type": "string"
                    },
                    "questionId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "newDisease": {
                        "type": "array",
                        "items": {
                            "type": "string"
                        }
                    }
                }
            },
            "IntakeCaseCreateRequest": {
                "type": "object",
                "properties": {
                    "questions": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/QuestionsSave"
                        }
                    },
                    "caregiver": {
                        "$ref": "#/components/schemas/CaregiverCreateRequest"
                    },
                    "careReceiver": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/CareReceiverCreateRequest"
                        }
                    },
                    "caseId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "organizationId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "caregiverId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "careReceiverId": {
                        "type": "integer",
                        "format": "int32"
                    }
                }
            },
            "QuestionsSave": {
                "type": "object",
                "properties": {
                    "answered": {
                        "type": "boolean"
                    },
                    "textBoxEnabled": {
                        "type": "boolean"
                    },
                    "seqNum": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "pageNum": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "questionId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "questionText": {
                        "type": "string"
                    },
                    "answers": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/Answers"
                        }
                    },
                    "dynamicScreener": {
                        "type": "boolean"
                    }
                }
            },
            "ReferralCases": {
                "type": "object",
                "properties": {
                    "orgCode": {
                        "type": "string"
                    },
                    "campaignId": {
                        "type": "string"
                    },
                    "organizationId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "referralList": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/ReferrerCaseCreateRequest"
                        }
                    }
                }
            },
            "UserField": {
                "type": "object",
                "properties": {
                    "active": {
                        "type": "boolean"
                    },
                    "fieldName": {
                        "type": "string"
                    },
                    "fieldId": {
                        "type": "integer",
                        "format": "int32"
                    }
                }
            },
            "CaseReminderCreateRequest": {
                "type": "object",
                "properties": {
                    "caseId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "activeIndicator": {
                        "type": "boolean"
                    },
                    "organizationId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "notes": {
                        "type": "string"
                    },
                    "reminderDate": {
                        "type": "string"
                    },
                    "reminderId": {
                        "type": "integer",
                        "format": "int32"
                    }
                }
            },
            "CaseReminder": {
                "type": "object",
                "properties": {
                    "createdAt": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "modifiedBy": {
                        "type": "string"
                    },
                    "modifiedAt": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "caseId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "createdBy": {
                        "type": "string"
                    },
                    "activeIndicator": {
                        "type": "boolean"
                    },
                    "notes": {
                        "type": "string"
                    },
                    "reminderDate": {
                        "type": "string"
                    },
                    "createdById": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "reminderId": {
                        "type": "integer",
                        "format": "int32"
                    }
                }
            },
            "CaseCreateRequest": {
                "type": "object",
                "properties": {
                    "caregiver": {
                        "$ref": "#/components/schemas/CaregiverCreateRequest"
                    },
                    "careReceiver": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/CareReceiverCreateRequest"
                        }
                    },
                    "caseId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "organizationId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "pcspDate": {
                        "type": "string",
                        "format": "date-time"
                    }
                }
            },
            "CaseCloseRequest": {
                "type": "object",
                "properties": {
                    "closedDt": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "caseId": {
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    "otherClosedReason": {
                        "type": "string"
                    },
                    "closedReasonId": {
                        "type": "integer",
                        "format": "int32"
                    }
                }
            },
            "ResourceUtilization": {
                "type": "object",
                "properties": {
                    "selectedServiceId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "sigDate": {
                        "type": "string"
                    },
                    "startDate": {
                        "type": "string"
                    },
                    "facilityId": {
                        "type": "string"
                    },
                    "rdbId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "rating": {
                        "type": "string"
                    },
                    "notes": {
                        "type": "string"
                    },
                    "rdbDetails1": {
                        "type": "string"
                    },
                    "used": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "userId": {
                        "type": "integer",
                        "format": "int32"
                    }
                }
            },
            "CaseFileUpload": {
                "type": "object",
                "properties": {
                    "file": {
                        "type": "string"
                    },
                    "fileName": {
                        "type": "string"
                    },
                    "fileId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "caseId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "details": {
                        "type": "string"
                    },
                    "uploadDate": {
                        "type": "string",
                        "format": "date-time"
                    }
                }
            },
            "PplServiceUsage": {
                "type": "object",
                "properties": {
                    "year": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "units": {
                        "type": "number",
                        "format": "float"
                    },
                    "month": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "day": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "fundingId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "fundingName": {
                        "type": "string"
                    },
                    "totalCost": {
                        "type": "number",
                        "format": "float"
                    },
                    "vendorName": {
                        "type": "string"
                    },
                    "cost": {
                        "type": "number",
                        "format": "float"
                    },
                    "display": {
                        "type": "string"
                    },
                    "unitId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "unitName": {
                        "type": "string"
                    },
                    "vendorId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "serviceUsageId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "details": {
                        "type": "string"
                    },
                    "pplId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "pplServiceUsageId": {
                        "type": "integer",
                        "format": "int64"
                    }
                }
            },
            "CarePlanPrintRequest": {
                "type": "object",
                "properties": {
                    "timeZone": {
                        "type": "string"
                    },
                    "orgId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "caseId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "assessId": {
                        "type": "integer",
                        "format": "int64"
                    },
                    "draft": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "cpId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "lang": {
                        "type": "string"
                    },
                    "step": {
                        "type": "integer",
                        "format": "int32"
                    }
                }
            },
            "AdhocRequest": {
                "type": "object",
                "properties": {
                    "adhocContent": {
                        "type": "string"
                    },
                    "deleted": {
                        "type": "boolean"
                    },
                    "serviceId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "adhocTemplate": {
                        "type": "string"
                    },
                    "serviceName": {
                        "type": "string"
                    }
                }
            },
            "PasswordChangeRequest": {
                "type": "object",
                "properties": {
                    "userName": {
                        "type": "string"
                    },
                    "password": {
                        "type": "string"
                    }
                }
            },
            "NotifiationUpdateRequest": {
                "type": "object",
                "properties": {
                    "enabled": {
                        "type": "boolean"
                    },
                    "notificationId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "emailAddress": {
                        "type": "string"
                    }
                }
            },
            "CaseReminderUpdateRequest": {
                "type": "object",
                "properties": {
                    "caseId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "activeIndicator": {
                        "type": "boolean"
                    },
                    "organizationId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "notes": {
                        "type": "string"
                    },
                    "reminderDate": {
                        "type": "string"
                    },
                    "reminderId": {
                        "type": "integer",
                        "format": "int32"
                    }
                }
            },
            "PersonUpdateRequest": {
                "type": "object",
                "properties": {
                    "address": {
                        "$ref": "#/components/schemas/StreetAddress"
                    },
                    "language": {
                        "type": "string"
                    },
                    "modifiedBy": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "subscriberId": {
                        "type": "string"
                    },
                    "caseId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "asian": {
                        "type": "boolean"
                    },
                    "black": {
                        "type": "boolean"
                    },
                    "educationId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "employmentId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "hispanic": {
                        "type": "boolean"
                    },
                    "homePhone": {
                        "type": "string"
                    },
                    "incomeId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "middleEastern": {
                        "type": "boolean"
                    },
                    "middleName": {
                        "type": "string"
                    },
                    "nativeAmerican": {
                        "type": "boolean"
                    },
                    "pacificIslander": {
                        "type": "boolean"
                    },
                    "pgName": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "maritalStat": {
                        "type": "string"
                    },
                    "assessType": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "gender": {
                        "type": "string"
                    },
                    "pronouns": {
                        "type": "string"
                    },
                    "disability": {
                        "type": "string"
                    },
                    "careManagerId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "personId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "dobDay": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "dobMonth": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "dobYear": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "otherRelationship": {
                        "type": "string"
                    },
                    "relationshipId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "fromDate": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "toDate": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "vipStatus": {
                        "type": "boolean"
                    },
                    "firstName": {
                        "type": "string"
                    },
                    "lastName": {
                        "type": "string"
                    },
                    "emailAddress": {
                        "type": "string"
                    },
                    "areaCode": {
                        "type": "string"
                    },
                    "phone": {
                        "type": "string"
                    },
                    "uninsured": {
                        "type": "boolean"
                    },
                    "preferredName": {
                        "type": "string"
                    },
                    "ssn": {
                        "type": "string"
                    },
                    "white": {
                        "type": "boolean"
                    },
                    "concentToContat": {
                        "type": "boolean"
                    },
                    "samsId": {
                        "type": "string"
                    },
                    "multiRacial": {
                        "type": "boolean"
                    }
                }
            },
            "UpdateOrganizationRequest": {
                "required": [
                    "phone",
                    "updateMask"
                ],
                "type": "object",
                "properties": {
                    "emailNotifi1": {
                        "type": "boolean"
                    },
                    "emailNotifi2": {
                        "type": "boolean"
                    },
                    "emailNotifi3": {
                        "type": "boolean"
                    },
                    "emailNotifi4": {
                        "type": "boolean"
                    },
                    "emailNotifi5": {
                        "type": "boolean"
                    },
                    "emailNotifi6": {
                        "type": "boolean"
                    },
                    "emailNotifi7": {
                        "type": "boolean"
                    },
                    "emailNotifi8": {
                        "type": "boolean"
                    },
                    "emailNotifi9": {
                        "type": "boolean"
                    },
                    "emailNotif10": {
                        "type": "boolean"
                    },
                    "emailNotifi11": {
                        "type": "boolean"
                    },
                    "emailNotifi12": {
                        "type": "boolean"
                    },
                    "emailNotifi13": {
                        "type": "boolean"
                    },
                    "emailNotifi14": {
                        "type": "boolean"
                    },
                    "emailNotifi15": {
                        "type": "boolean"
                    },
                    "orgCode": {
                        "type": "string"
                    },
                    "reminder1": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "reminder2": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "reminder3": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "automaticReminder": {
                        "type": "boolean"
                    },
                    "userCreateMailAlert": {
                        "type": "boolean"
                    },
                    "organizationAddress": {
                        "$ref": "#/components/schemas/StreetAddress"
                    },
                    "activeTimeoutInMs": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "apiServiceActivated": {
                        "type": "boolean"
                    },
                    "iddServiceActivated": {
                        "type": "boolean"
                    },
                    "inactiveTimeoutInMs": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "militaryServiceActivated": {
                        "type": "boolean"
                    },
                    "resourceDatabaseApi": {
                        "type": "boolean"
                    },
                    "schedulingActivated": {
                        "type": "boolean"
                    },
                    "telephonyActivated": {
                        "type": "boolean"
                    },
                    "calendarId": {
                        "type": "string"
                    },
                    "chatbotActivated": {
                        "type": "boolean"
                    },
                    "dynamicScreener": {
                        "type": "boolean"
                    },
                    "lockoutTimeInMs": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "loginAttempts": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "primaryContact": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "reportActivated": {
                        "type": "boolean"
                    },
                    "sicCd": {
                        "type": "string"
                    },
                    "followupDays": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "filterRdb": {
                        "type": "boolean"
                    },
                    "caremanagerMail": {
                        "type": "boolean"
                    },
                    "passwordExpirationAge": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "organizationUpload": {
                        "type": "boolean"
                    },
                    "licenseExpirationDate": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "specializedQuestions": {
                        "type": "boolean"
                    },
                    "rescreenActivated": {
                        "type": "boolean"
                    },
                    "referralDyadRequired": {
                        "type": "boolean"
                    },
                    "subscriberIdRequired": {
                        "type": "boolean"
                    },
                    "caregiverMail": {
                        "type": "boolean"
                    },
                    "cgIntake": {
                        "type": "boolean"
                    },
                    "dobFullRequired": {
                        "type": "boolean"
                    },
                    "contactCG": {
                        "type": "boolean"
                    },
                    "showSSN": {
                        "type": "boolean"
                    },
                    "showCampaign": {
                        "type": "boolean"
                    },
                    "zipCodeEnableCR": {
                        "type": "boolean"
                    },
                    "vipEnabled": {
                        "type": "boolean"
                    },
                    "statId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "updateMask": {
                        "type": "array",
                        "items": {
                            "type": "string",
                            "enum": [
                                "ACTIVE",
                                "ACTIVE_TIMEOUT_IN_MS",
                                "API_SERVICE_ACTIVATED",
                                "AREA_CODE",
                                "CALENDAR_ID",
                                "CHATBOT_ACTIVATED",
                                "DYNAMIC_SCREENER",
                                "IDD_SERVICE_ACTIVATED",
                                "INACTIVE_TIMEOUT_IN_MS",
                                "LICENSE_EXPIRATION_DATE",
                                "LICENSES",
                                "LOCKOUT_TIME_IN_MS",
                                "LOGIN_ATTEMPTS",
                                "MILITARY_SERVICE_ACTIVATED",
                                "ORGANIZATION_ADDRESS_ADDRESS_LINE_1",
                                "ORGANIZATION_ADDRESS_ADDRESS_LINE_2",
                                "ORGANIZATION_ADDRESS_ADDRESS_LINE_3",
                                "ORGANIZATION_ADDRESS_CITY",
                                "ORGANIZATION_ADDRESS_STATE",
                                "ORGANIZATION_ADDRESS_POSTAL_CODE",
                                "ORGANIZATION_ADDRESS_COUNTRY",
                                "ORGANIZATION_NAME",
                                "PHONE",
                                "PRIMARY_CONTACT",
                                "REPORT_ACTIVATED",
                                "RESOURCE_DATABASE_API",
                                "SCHEDULING_ACTIVATED",
                                "SIC",
                                "TELEPHONY_ACTIVATED"
                            ]
                        }
                    },
                    "areaCode": {
                        "type": "string"
                    },
                    "licenses": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "phone": {
                        "type": "string"
                    },
                    "organizationName": {
                        "type": "string"
                    }
                }
            },
            "UserUpdateRequest": {
                "type": "object",
                "properties": {
                    "address": {
                        "$ref": "#/components/schemas/StreetAddress"
                    },
                    "year": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "month": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "day": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "asian": {
                        "type": "boolean"
                    },
                    "black": {
                        "type": "boolean"
                    },
                    "educationId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "employmentId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "homePhone": {
                        "type": "string"
                    },
                    "incomeId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "middleEastern": {
                        "type": "boolean"
                    },
                    "middleName": {
                        "type": "string"
                    },
                    "prefferedName": {
                        "type": "string"
                    },
                    "gender": {
                        "type": "string"
                    },
                    "education": {
                        "type": "string"
                    },
                    "employment": {
                        "type": "string"
                    },
                    "maritalStatus": {
                        "type": "string"
                    },
                    "roles": {
                        "type": "array",
                        "items": {
                            "type": "string"
                        }
                    },
                    "income": {
                        "type": "string"
                    },
                    "email": {
                        "type": "string"
                    },
                    "rolesId": {
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    "pplId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "refused": {
                        "type": "boolean"
                    },
                    "firstName": {
                        "type": "string"
                    },
                    "lastName": {
                        "type": "string"
                    },
                    "phone": {
                        "type": "string"
                    },
                    "consentToContact": {
                        "type": "boolean"
                    },
                    "hispanicOrLatino": {
                        "type": "boolean"
                    },
                    "nativeHawaiian": {
                        "type": "boolean"
                    },
                    "userName": {
                        "type": "string"
                    },
                    "americanIndian": {
                        "type": "boolean"
                    },
                    "ssn": {
                        "type": "string"
                    },
                    "white": {
                        "type": "boolean"
                    },
                    "userId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "status": {
                        "type": "string",
                        "enum": [
                            "UNKNOWN",
                            "ACTIVE",
                            "DEACTIVATED",
                            "TERMINATED",
                            "PENDING_CERTIFICATION"
                        ]
                    }
                }
            },
            "SummarySubmitRequest": {
                "type": "object",
                "properties": {
                    "questions": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/QuestionsSave"
                        }
                    },
                    "caseId": {
                        "type": "integer",
                        "format": "int64"
                    },
                    "assessmentId": {
                        "type": "integer",
                        "format": "int64"
                    }
                }
            },
            "AssessmentSubmitRequest": {
                "type": "object",
                "properties": {
                    "timeZone": {
                        "type": "string"
                    },
                    "questions": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/QuestionsSave"
                        }
                    },
                    "assessmentComplete": {
                        "type": "boolean"
                    },
                    "orgCode": {
                        "type": "string"
                    },
                    "orgId": {
                        "type": "integer",
                        "format": "int64"
                    },
                    "campaignId": {
                        "type": "string"
                    },
                    "caseId": {
                        "type": "integer",
                        "format": "int64"
                    },
                    "assessmentId": {
                        "type": "integer",
                        "format": "int64"
                    },
                    "pageNumber": {
                        "type": "integer",
                        "format": "int32"
                    }
                }
            },
            "NotesUpdateRequest": {
                "type": "object",
                "properties": {
                    "notes": {
                        "type": "string"
                    },
                    "notesId": {
                        "type": "integer",
                        "format": "int32"
                    }
                }
            },
            "CaseReminderModel": {
                "type": "object",
                "properties": {
                    "caseId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "activeIndicator": {
                        "type": "boolean"
                    },
                    "organizationId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "notes": {
                        "type": "string"
                    },
                    "reminderDate": {
                        "type": "string"
                    },
                    "reminderId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "links": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/Link"
                        }
                    }
                }
            },
            "CaseRelationshipUpdate": {
                "type": "object",
                "properties": {
                    "caseId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "otherRelationship": {
                        "type": "string"
                    },
                    "relationshipId": {
                        "type": "integer",
                        "format": "int32"
                    }
                }
            },
            "Caregiver": {
                "type": "object",
                "properties": {
                    "orgContact": {
                        "type": "boolean"
                    },
                    "email": {
                        "type": "string"
                    },
                    "dobDay": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "dobMonth": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "dobYear": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "otherRace": {
                        "type": "boolean"
                    },
                    "asian": {
                        "type": "boolean"
                    },
                    "black": {
                        "type": "boolean"
                    },
                    "hispanic": {
                        "type": "boolean"
                    },
                    "middleEastern": {
                        "type": "boolean"
                    },
                    "nativeAmerican": {
                        "type": "boolean"
                    },
                    "pacificIslander": {
                        "type": "boolean"
                    },
                    "refused": {
                        "type": "boolean"
                    },
                    "white": {
                        "type": "boolean"
                    },
                    "concentToContat": {
                        "type": "boolean"
                    },
                    "firstName": {
                        "type": "string"
                    },
                    "lastName": {
                        "type": "string"
                    },
                    "cgId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "phone": {
                        "type": "string"
                    }
                }
            },
            "CareReceiver": {
                "type": "object",
                "properties": {
                    "orgContact": {
                        "type": "boolean"
                    },
                    "subscriberId": {
                        "type": "string"
                    },
                    "otherRelationship": {
                        "type": "string"
                    },
                    "relationship": {
                        "type": "string"
                    },
                    "firstName": {
                        "type": "string"
                    },
                    "lastName": {
                        "type": "string"
                    },
                    "crId": {
                        "type": "integer",
                        "format": "int32"
                    }
                }
            },
            "ReassessmentReason": {
                "type": "object",
                "properties": {
                    "reassessmentReason": {
                        "type": "integer",
                        "format": "int32"
                    }
                }
            },
            "NotificationResponse": {
                "type": "object",
                "properties": {
                    "notificationId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "email": {
                        "type": "string"
                    },
                    "notificationName": {
                        "type": "string"
                    },
                    "notificationEnabled": {
                        "type": "boolean"
                    }
                }
            },
            "ChildOrganization": {
                "type": "object",
                "properties": {
                    "organizationId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "organizationName": {
                        "type": "string"
                    }
                }
            },
            "Pageable": {
                "type": "object",
                "properties": {
                    "offset": {
                        "type": "integer",
                        "format": "int64"
                    },
                    "sort": {
                        "$ref": "#/components/schemas/Sort"
                    },
                    "unpaged": {
                        "type": "boolean"
                    },
                    "paged": {
                        "type": "boolean"
                    },
                    "pageNumber": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "pageSize": {
                        "type": "integer",
                        "format": "int32"
                    }
                }
            },
            "Sort": {
                "type": "object",
                "properties": {
                    "empty": {
                        "type": "boolean"
                    },
                    "unsorted": {
                        "type": "boolean"
                    },
                    "sorted": {
                        "type": "boolean"
                    }
                }
            },
            "AssessmentHistory": {
                "type": "object",
                "properties": {
                    "summaryCompleted": {
                        "type": "boolean"
                    },
                    "screenercase": {
                        "type": "boolean"
                    },
                    "careplanId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "careplanStatus": {
                        "type": "string"
                    },
                    "cloneEnabled": {
                        "type": "boolean"
                    },
                    "followUpDate": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "caseStatId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "assessStatId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "careplanStatId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "cpClone": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "screenerCount": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "submittedBy": {
                        "type": "string"
                    },
                    "assessmentCreatedDate": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "assessmentId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "careplanCompleted": {
                        "type": "boolean"
                    },
                    "assessmentCompleted": {
                        "type": "boolean"
                    },
                    "caseStatus": {
                        "type": "string",
                        "enum": [
                            "UNKNOWN",
                            "SCREENER_INPROGRESS",
                            "SCREENER_COMPLETED",
                            "PENDING_ASSESSMENT",
                            "ASSESSMENT_INPROGRESS",
                            "PENDING_CAREPLAN",
                            "PENDING_FLLOWUP_ASSESSMENT",
                            "FOLLOWUP_ASSESSMENT_INPROGRESS",
                            "CLOSED",
                            "ASSESSMENT_COMPLETED",
                            "PENDING_SUMMARY",
                            "SUMMARY_INPROGRESS",
                            "SUMMARY_COMPLETED",
                            "CAREPLAN_INPROGRESS",
                            "CAREPLAN_COMPLETED",
                            "PENDING_FOLLOWUP_SUMMARY",
                            "FOLLOWUP_COMPLETED",
                            "PENDING_FOLLOWUP_CAREPLAN",
                            "FOLLOWUP_CAREPLAN_INPROGRESS",
                            "FOLLOWUP_CAREPLAN",
                            "FOLLOWUP_SUMMARY_INPROGRESS",
                            "FOLLOWUP_SUMMARY_COMPLETED",
                            "FOLLOWUP_ASSESSMENT_COMPLETED",
                            "FOLLOWUP_SCREENER_COMPLETED",
                            "PENDING_REFERRER",
                            "FOLLOWUP_SCREENER_INPROGRESS"
                        ]
                    },
                    "assessmentType": {
                        "type": "string"
                    },
                    "careplanCompletedDate": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "assessmentStatus": {
                        "type": "string"
                    },
                    "assessmentCompletedDate": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "reason": {
                        "type": "string"
                    }
                }
            },
            "CasePerson": {
                "type": "object",
                "properties": {
                    "name": {
                        "type": "string"
                    },
                    "id": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "address": {
                        "$ref": "#/components/schemas/StreetAddress"
                    },
                    "language": {
                        "type": "string"
                    },
                    "timeZone": {
                        "type": "string"
                    },
                    "insuranceId": {
                        "type": "array",
                        "items": {
                            "type": "integer",
                            "format": "int32"
                        }
                    },
                    "alcohol": {
                        "type": "string"
                    },
                    "miltary": {
                        "type": "string"
                    },
                    "subscriberId": {
                        "type": "string"
                    },
                    "health": {
                        "type": "string"
                    },
                    "homePhone": {
                        "type": "string"
                    },
                    "pgName": {
                        "type": "string"
                    },
                    "timeZoneOffset": {
                        "type": "string"
                    },
                    "careManagerName": {
                        "type": "string"
                    },
                    "assessType": {
                        "type": "string"
                    },
                    "gender": {
                        "type": "string"
                    },
                    "education": {
                        "type": "string"
                    },
                    "employment": {
                        "type": "string"
                    },
                    "pronouns": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "insurance": {
                        "type": "string"
                    },
                    "disability": {
                        "type": "string"
                    },
                    "income": {
                        "type": "string"
                    },
                    "careManagerId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "email": {
                        "type": "string"
                    },
                    "dobDay": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "dobMonth": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "dobYear": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "otherRace": {
                        "type": "boolean"
                    },
                    "asian": {
                        "type": "boolean"
                    },
                    "black": {
                        "type": "boolean"
                    },
                    "hispanic": {
                        "type": "boolean"
                    },
                    "middleEastern": {
                        "type": "boolean"
                    },
                    "nativeAmerican": {
                        "type": "boolean"
                    },
                    "pacificIslander": {
                        "type": "boolean"
                    },
                    "refused": {
                        "type": "boolean"
                    },
                    "white": {
                        "type": "boolean"
                    },
                    "fromDate": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "toDate": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "vipStatus": {
                        "type": "boolean"
                    },
                    "relationship": {
                        "type": "string"
                    },
                    "phone": {
                        "type": "string"
                    },
                    "preferredName": {
                        "type": "string"
                    }
                }
            },
            "CaseSummaryModel": {
                "type": "object",
                "properties": {
                    "caseType": {
                        "type": "string"
                    },
                    "cgLanguage": {
                        "type": "string"
                    },
                    "crDiagnosis": {
                        "type": "string"
                    },
                    "crMemory": {
                        "type": "string"
                    },
                    "screener": {
                        "type": "boolean"
                    },
                    "modifiedOn": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "assessmentCount": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "scheduledAt": {
                        "type": "string"
                    },
                    "cgPhone": {
                        "type": "string"
                    },
                    "cgHomePhone": {
                        "type": "string"
                    },
                    "cgInsurance": {
                        "type": "string"
                    },
                    "measureHistory": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/MeasureHistory"
                        }
                    },
                    "recentNotes": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "referredByPhone": {
                        "type": "string"
                    },
                    "referredByEmail": {
                        "type": "string"
                    },
                    "caseId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "rescreenActivated": {
                        "type": "boolean"
                    },
                    "organizationId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "pcspDate": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "cgEmail": {
                        "type": "string"
                    },
                    "cgInfo": {
                        "$ref": "#/components/schemas/CasePerson"
                    },
                    "crInfo": {
                        "$ref": "#/components/schemas/CasePerson"
                    },
                    "createdOn": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "referredBy": {
                        "type": "string"
                    },
                    "assessmentHistory": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/AssessmentHistory"
                        }
                    },
                    "schedulingEnabled": {
                        "type": "boolean"
                    },
                    "status": {
                        "type": "string",
                        "enum": [
                            "UNKNOWN",
                            "SCREENER_INPROGRESS",
                            "SCREENER_COMPLETED",
                            "PENDING_ASSESSMENT",
                            "ASSESSMENT_INPROGRESS",
                            "PENDING_CAREPLAN",
                            "PENDING_FLLOWUP_ASSESSMENT",
                            "FOLLOWUP_ASSESSMENT_INPROGRESS",
                            "CLOSED",
                            "ASSESSMENT_COMPLETED",
                            "PENDING_SUMMARY",
                            "SUMMARY_INPROGRESS",
                            "SUMMARY_COMPLETED",
                            "CAREPLAN_INPROGRESS",
                            "CAREPLAN_COMPLETED",
                            "PENDING_FOLLOWUP_SUMMARY",
                            "FOLLOWUP_COMPLETED",
                            "PENDING_FOLLOWUP_CAREPLAN",
                            "FOLLOWUP_CAREPLAN_INPROGRESS",
                            "FOLLOWUP_CAREPLAN",
                            "FOLLOWUP_SUMMARY_INPROGRESS",
                            "FOLLOWUP_SUMMARY_COMPLETED",
                            "FOLLOWUP_ASSESSMENT_COMPLETED",
                            "FOLLOWUP_SCREENER_COMPLETED",
                            "PENDING_REFERRER",
                            "FOLLOWUP_SCREENER_INPROGRESS"
                        ]
                    },
                    "reason": {
                        "type": "boolean"
                    },
                    "links": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/Link"
                        }
                    }
                }
            },
            "ReferrerCaseFollowup": {
                "type": "object",
                "properties": {
                    "caseId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "followupDate": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "createdBy": {
                        "type": "string"
                    },
                    "reasonName": {
                        "type": "string"
                    },
                    "notes": {
                        "type": "string"
                    },
                    "rcfId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "createdDate": {
                        "type": "string",
                        "format": "date-time"
                    },
                    "reason": {
                        "type": "integer",
                        "format": "int32"
                    }
                }
            },
            "CasePersonInfo": {
                "type": "object",
                "properties": {
                    "caregiverName": {
                        "type": "string"
                    },
                    "careReceiverName": {
                        "type": "string"
                    },
                    "careManagerName": {
                        "type": "string"
                    },
                    "careManagerId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "caregiverId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "careReceiverId": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "relationship": {
                        "type": "string"
                    }
                }
            },
            "Communication": {
                "type": "object",
                "properties": {
                    "type": {
                        "type": "string"
                    },
                    "duration": {
                        "type": "string"
                    },
                    "recordsId": {
                        "type": "string"
                    },
                    "endTime": {
                        "type": "string"
                    },
                    "phoneNumber": {
                        "type": "string"
                    },
                    "requestId": {
                        "type": "string"
                    },
                    "status": {
                        "type": "string"
                    },
                    "startTime": {
                        "type": "string",
                        "format": "date-time"
                    }
                }
            },
            "CommunicationSummaryModel": {
                "type": "object",
                "properties": {
                    "cgCommunication": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/Communication"
                        }
                    },
                    "personInfo": {
                        "$ref": "#/components/schemas/CasePersonInfo"
                    },
                    "links": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/Link"
                        }
                    }
                }
            }
        }
    }
}