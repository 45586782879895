
import './App.css';
import { specs } from './specs';
import SwaggerUI from 'swagger-ui-react';

import "swagger-ui-react/swagger-ui.css"

function App() {
  return (
    <SwaggerUI spec={specs} />
  );
}

export default App;
